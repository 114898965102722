// export const fonts = [
//   "Helvetica",
//   "Alex Brush",
//   "Covered By Your Grace",
//   "Give You Glory",
//   "Great Vibes",
//   "Monsieur La Doulaise",
//   "Mr Dafoe",
//   "Sacramento",
//   "Stalemate",
// ];
export const fonts = [
  {
    name: "Helvetica",
    fontClass: "Helvetica",
  },
  {
    name: "Alex Brush",
    fontClass: "AlexBrush",
  },
  {
    name: "Covered By Your Grace",
    fontClass: "CoveredByYourGrace",
  },
  {
    name: "Give You Glory",
    fontClass: "GiveYouGlory",
  },
  {
    name: "Great Vibes",
    fontClass: "GreatVibes",
  },
  {
    name: "Monsieur La Doulaise",
    fontClass: "MonsieurLaDoulaise",
  },
  {
    name: "Mr Dafoe",
    fontClass: "MrDafoe",
  },
  {
    name: "Sacramento",
    fontClass: "Sacramento",
  },
  {
    name: "Stalemate",
    fontClass: "Stalemate",
  },
];
// export const fontSizes = [20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40];
export const fontSizes = Array.from({ length: 99 }, (v, i) => i + 2);

export const colors = [
  "#000",
  "#bbb",
  "#04C413",
  "#FB0202",
  "#0F00FE",
  "#D000FE",
  "#F0FB02",
];
