import { Navigate } from "react-router-dom";

interface ParentCompProps {
  childComp?: React.ReactNode;
}

const Protected: React.FC<ParentCompProps> = (props: any) => {
  const { children } = props;
  let isLoggedIn =
    sessionStorage.getItem("accessToken") !== null ? true : false;
  if (!isLoggedIn) {
    return <Navigate to="/" replace />;
  } else {
    return children;
  }
};
export default Protected;
