import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

export const LinkExpiredMsg: React.FC<{}> = ({}) => {
  return (
    <>
      <div className="py-1 px-4 lg:px-15">
        <div
          className="bg-white  py-10 my-10 border-2 "
          style={{ backgroundColor: "white", boxShadow: "1px 1px 3px grey" }}
        >
          <div className="">
            <h2 className="text-black  text-center opacity-80">
              Document has been expired..
            </h2>
          </div>

          <div className="flex items-center justify-center mt-4 flex-col">
            <Link to="/">
              {/* <Button variant="contained">Go to home</Button> */}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
