import { Divider } from "@mui/material";
import React, { useRef, useState } from "react";
import { convertBase64 } from "../../helpers/Helpers";
import FileUpload, { fileTypes } from "../../fileuploader/FileUpload";

interface SignatureUploaderProps {
  setSignInitialsDetails: React.Dispatch<any>;
  signInitialsDetails: any;
}

export const SignatureUploader: React.FC<SignatureUploaderProps> = ({
  setSignInitialsDetails,
  signInitialsDetails,
}) => {
  const [signatureImg, setSignatureImg] = useState<any[]>([]);
  const [initialsImg, setInitialsImg] = useState<any[]>([]);

  const handleFileUpload = async (
    event: { currentTarget: { files: any } },
    type: string
  ) => {
    if (event.currentTarget) {
      // console.log([...event.currentTarget.files]);
      let fileArray: any[] = [];
      let file = event.currentTarget.files[0];
      [...event.currentTarget.files].forEach((file) => {
        if (fileTypes.includes(file.name.split(".").pop())) {
          fileArray.push(file);
        } else {
        }
      });

      const base64 = await convertBase64(file);
      // console.log({ base64 });

      if (type === "sign") {
        setSignatureImg([...fileArray]);
        setSignInitialsDetails({
          ...signInitialsDetails,
          signImage: base64,
        });
      } else {
        setInitialsImg([...fileArray]);
        setSignInitialsDetails({
          ...signInitialsDetails,
          initialImage: base64,
        });
      }
    }
  };

  return (
    <div className="flex items-center justify-center w-full">
      <div className="w-2/3 mx-2">
        <FileUpload
          multiple={false}
          DisplayText={signatureText}
          format={true}
          files={signatureImg}
          setFiles={setSignatureImg}
          handleFileUpload={handleFileUpload}
          type="sign"
        />
      </div>
      <Divider orientation="vertical" flexItem className="pt-1" />
      <div className="w-2/5 mx-2">
        <FileUpload
          multiple={false}
          DisplayText={initialsText}
          format={true}
          files={initialsImg}
          setFiles={setInitialsImg}
          handleFileUpload={handleFileUpload}
        />
      </div>
    </div>
  );
};

const signatureText = (
  <p className="text-black opacity-70 font-semibold ">
    Drag and Drop or
    <span className="text-red ml-1">Browser Signature</span>
  </p>
);

const initialsText = (
  <p className="text-black opacity-70 font-semibold ">
    Drag and Drop or
    <span className="text-red ml-1">Browser Initials</span>
  </p>
);
