export const Managereducer = (state: any, action: any): any => {
  switch (action.type) {
    case "GET_DOCUMENTDETAILS":
      return {
        ...state,
        documentDetails: action.payload,
        loading: false,
      };

    case "GET_ALLPENDINGDOCUMENT":
      return {
        ...state,
        allpendingdocument: action.payload,
        loading: false,
      };

    case "SET_LOADING":
      return {
        ...state,
        loading: true,
      };

    case "SET_CURRENTDOCUMENTHEADERID":
      localStorage.setItem("currentDocumentHeaderId", action.payload);
      return {
        ...state,
        currentDocumentHeaderId: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};
