import { Checkbox } from "@mui/material";
import React, { useState } from "react";
import styles from "../../../styles/Sign.module.css";

export const dataFont = [
  {
    id: "1",
    fontClass: "AlexBrush",
  },
  {
    id: "2",
    fontClass: "CoveredByYourGrace",
  },
  {
    id: "3",
    fontClass: "GiveYouGlory",
  },
  {
    id: "4",
    fontClass: "GreatVibes",
  },
  {
    id: "5",
    fontClass: "MonsieurLaDoulaise",
  },
  {
    id: "6",
    fontClass: "MrDafoe",
  },
  {
    id: "7",
    fontClass: "Sacramento",
  },
  {
    id: "8",
    fontClass: "Stalemate",
  },
];

export let fontFamilies: any = {};
// dataFont.map((item) => {
//   fontFamilies[item.id] = item.fontClass;
// });
fontFamilies = {
  "1": "Alex Brush",
  "2": "Covered By Your Grace",
  "3": "Give You Glory",
  "4": "Great Vibes",
  "5": "Monsieur La Doulaise",
  "6": "Mr Dafoe",
  "7": "Sacramento",
  "8": "Stalemate",
};
const label = { inputProps: { "aria-label": "Checkbox demo" } };

interface SignatureSelectProps {
  fullName: string;
  initials: string;
  setSignInitialsDetails: React.Dispatch<any>;
  signInitialsDetails: any;
}

export const SignatureSelect: React.FC<SignatureSelectProps> = ({
  fullName,
  initials,
  setSignInitialsDetails,
  signInitialsDetails,
}) => {
  const [signType, setSignType] = useState<boolean[]>(
    new Array(dataFont.length).fill(false)
  );

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    console.log(id);
    const newSignType = signType.map((item, index) => {
      if (index === id) {
        return true;
      }
      return false;
    });

    setSignType(newSignType);
    setSignInitialsDetails({
      ...signInitialsDetails,
      fontId: dataFont[id].id,
    });
  };

  return (
    <div className="p-2">
      <div className="flex items-center justify-center flex-col w-full overflow-auto">
        {dataFont.map(({ id, fontClass }, idx) => (
          <div
            key={id}
            className={`flex items-center justify-start w-full px-2  ${
              idx % 2 !== 0 ? "bg-primary-100" : "bg-primary-bg"
            }`}
          >
            <Checkbox
              {...label}
              checked={signType[idx]}
              onChange={(e) => handleChange(e, idx)}
            />
            <div className={`${styles.chooseCard}`}>
              <p
                className={`text-black opacity-70 font-semibold absolute -top-2 ${
                  idx % 2 !== 0 ? "bg-primary-100" : "bg-primary-bg"
                } px-1`}
              >
                Signed By:
              </p>
              <div className="w-full text-center overflow-hidden mt-1">
                <p className={`w-auto text-2xl ${fontClass}`}>{fullName}</p>
              </div>
              {/* <p
                className={`text-black opacity-70 font-semibold absolute -bottom-2 ${
                  idx % 2 !== 0 ? "bg-primary-100" : "bg-primary-bg"
                } px-1 flex-wrap`}
              >
                {"5a064df6-dcab-46fa..."}
              </p> */}
            </div>
            <div className={`${styles.chooseCard}`}>
              <p
                className={`text-black opacity-70 font-semibold absolute -top-2 ${
                  idx % 2 !== 0 ? "bg-primary-100" : "bg-primary-bg"
                } px-1`}
              >
                Initials
              </p>
              <div className="w-full text-left overflow-hidden mt-1">
                <p className={`w-auto text-2xl ${fontClass}`}>{initials}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
