import React, { useContext, useEffect, useState } from "react";
import { GrDrag } from "react-icons/gr";
import DocumentDetailsContext from "../../context/DocumentsDetails/documentDetails.context";
import Autosuggest from "react-autosuggest";
import { Draggable } from "react-beautiful-dnd";
import { getRandomIndex } from "../Documentdetails/SelectRecipients";
const grid = 1;

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  ...draggableStyle,
  top: "auto !important",
  left: "auto !important",
});

interface InputsProps {
  index?: any;
  addRecipient: (data: any, index: any) => any;
  value: any;
  isCheckedIndex: boolean;
  pullDataFunc: (data: any) => void;
  updateDocument: (data: any, index: number) => void;
  removeRecipient: (index: number, id: any) => void;
}

const InputsDragable: React.FC<InputsProps> = ({
  pullDataFunc,
  isCheckedIndex,
  addRecipient,
  value,
  index,
  updateDocument,
  removeRecipient,
}) => {
  const [data, setData] = useState({
    name: value.name,
    emailId: value.emailId,
    signerId: value?.signerId,
  });
  const {
    recipientSuggestions,
    getRecipientSuggestions,
    removeRecipientSuggestion,
  } = useContext(DocumentDetailsContext);
  const [isRemove, setIsRemove] = useState<boolean>(true);

  console.log("recipientSuggestions", recipientSuggestions);
  useEffect(() => {
    if (index == 0) setIsRemove(false);
    else setIsRemove(true);
  }, [index]);

  const [isAdded, setIsAdded] = useState<boolean>(false);
  const onSuggestionsFetchRequested_name = async ({
    value,
  }: {
    value: string;
  }) => {
    if (value.length >= 2) await getRecipientSuggestions(value, null);
    else {
      await removeRecipientSuggestion();
    }
  };

  const onSuggestionsFetchRequested_email = async ({
    value,
  }: {
    value: string;
  }) => {
    if (value.length >= 2) await getRecipientSuggestions(null, value);
    else {
      await removeRecipientSuggestion();
    }
  };

  const onSuggestionsClearRequested = async () => {
    // await getRecipientSuggestions("2", "10", "", "");
  };

  const getSuggestionValueName = (suggestion: any) => {
    return suggestion.firstName;
  };
  const getSuggestionValueEmail = (suggestion: any) => {
    return suggestion.emailId;
  };
  const onSuggestionSelected = (event: any, { suggestion }: any) => {
    console.log("sugestion selected:", suggestion);
    setData({
      name: suggestion.firstName,
      emailId: suggestion.emailId,
      signerId: suggestion.signerId,
    });
    event.preventDefault();
  };

  const renderSuggestion = (suggestion: any) => (
    <span className="h-full bg-primary-1 w-full z-20">
      <span className="icon-wrap text-black"></span>
      <span className="name text-black">{suggestion.firstName}</span>
    </span>
  );
  const renderSuggestionEmail = (suggestion: any) => (
    <span className="h-full bg-primary-1 w-full z-10">
      <span className="icon-wrap text-black"></span>
      <span className="name text-black">{suggestion.emailId}</span>
    </span>
  );

  const shouldRenderSuggestions = (value: any, reason: any) => {
    return !isAdded;
  };
  const shouldRenderSuggestionsEmail = (value: any, reason: any) => {
    return false;
  };
  const handleChange = (
    value: string,
    name: string,
    e: React.FormEvent<HTMLElement>
  ) => {
    setData({
      ...data,
      [name]: value,
    });
    setIsAdded(false);
  };

  const [indexValue, setindexValue] = useState<any>({});
  const handlekeyup = () => {
    //console.log("handle key");
    pullDataFunc({ indexValue, value, index });
  };
  const onChangeIndex = (e: any) => {
    setindexValue(e.currentTarget.textContent);
  };
  const handleKeyPress = (e: any) => {
    // console.log("handle key pss", e.target.textContent);
    let idx = parseInt(String.fromCharCode(e.which), 10);
    if (isNaN(idx)) e.preventDefault();
    if (idx === 0 && e.target.textContent.length === 0) e.preventDefault();
  };
  return (
    <Draggable
      draggableId={value.id || String(value.idx + value?.emailId)}
      index={index}
      //  isDragDisabled={isCheckedIndex}
    >
      {(provided: any, snapshot: any) => (
        <div
          className={``}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
          {/* <div style={{color:"black"}}>Raktim</div> */}
          <div className="flex items-center justify-start w-full mt-3 relative h-8 custoumClass">
            <GrDrag className="cursor-grabbing text-base" />
            {isCheckedIndex ? (
              <div
                style={{
                  color: "black",
                  fontWeight: "bold",
                  marginLeft: "12px",
                  width: "20px",
                  padding: "2px",
                  fontSize: "12px",
                }}
                contentEditable={true}
                onInput={onChangeIndex}
                onBlur={handlekeyup}
                onKeyPress={handleKeyPress}
              >
                {value.idx}
              </div>
            ) : (
              <div
                style={{
                  color: "black",
                  fontWeight: "bold",
                  marginLeft: "12px",
                  width: "20px",
                  padding: "2px",
                  fontSize: "12px",
                }}
                onInput={onChangeIndex}
              ></div>
            )}
            <div className="flex flex-col items-start ml-4 relative ">
              <p className="text-black absolute top-[-25px] text-sm">
                Name<span className="text-red">*</span>
              </p>
              <div className="">
                <Autosuggest
                  suggestions={recipientSuggestions}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequested_name}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  onSuggestionSelected={onSuggestionSelected}
                  getSuggestionValue={getSuggestionValueName}
                  renderSuggestion={renderSuggestion}
                  shouldRenderSuggestions={shouldRenderSuggestions}
                  inputProps={{
                    value: data.name,
                    onChange: (e, { newValue }) => {
                      let stringVal = newValue.replace(/[^a-z ]*$/gi, "");
                      handleChange(stringVal, "name", e);
                    },
                    onBlur: (e) => {
                      updateDocument(data, index);
                    },
                  }}
                  renderInputComponent={(inputProps) => (
                    <input
                      style={{
                        width: "100%",
                        paddingInline: "10px",
                        paddingTop: "2px",
                        paddingBottom: "2px",
                        border: "2px solid black",
                        borderRadius: "0.375rem",
                        color: "black",
                        zIndex: 1,
                      }}
                      placeholder="Name"
                      type="text"
                      {...inputProps}
                    />
                  )}
                />
              </div>
            </div>
            <div className="flex flex-col items-start ml-4 relative">
              <p className="text-black absolute top-[-25px] text-sm">
                Email<span className="text-red">*</span>
              </p>
              <div>
                <Autosuggest
                  suggestions={recipientSuggestions}
                  onSuggestionsFetchRequested={
                    onSuggestionsFetchRequested_email
                  }
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  onSuggestionSelected={onSuggestionSelected}
                  getSuggestionValue={getSuggestionValueEmail}
                  renderSuggestion={renderSuggestionEmail}
                  shouldRenderSuggestions={shouldRenderSuggestionsEmail}
                  inputProps={{
                    value: data.emailId,
                    onChange: (e, { newValue }) => {
                      // Trim the newValue to remove leading and trailing spaces
                      const trimmedValue = newValue.trim();
                      handleChange(trimmedValue, "emailId", e);
                    },
                    onBlur: (e) => {
                      updateDocument(data, index);
                    },
                  }}
                  renderInputComponent={(inputProps) => (
                    <input
                      style={{
                        width: "calc(100% + 35%)",
                        paddingInline: "8px",
                        paddingTop: "1px",
                        paddingBottom: "1px",
                        border: "2px solid black",
                        borderRadius: "0.375rem",
                        color: "black",
                      }}
                      placeholder="Email"
                      type="text"
                      {...inputProps}
                    />
                  )}
                />
              </div>
            </div>
            {isRemove && (
              <div
                className="border-black rounded-lg bg-deletered cursor-pointer ml-8"
                onClick={() => {
                  setData({
                    name: "",
                    emailId: "",
                    signerId: "",
                  });

                  removeRecipient(index, value.id);
                  setIsRemove(false);
                }}
              >
                <p className="px-[8px] py-[1px]">Remove</p>
              </div>
            )}
          </div>
          {/* {!isRemove && (
            <div
              className="border  w-8 border-opacity-50 border-black rounded-lg hover:bg-primary-100 cursor-pointer add-btn"
              onClick={() => {
                let res = addRecipient(data, index);
                setIsRemove(res);
                setIsAdded(true);
              }}
            >
              <p className="px-[8px] py-[1px] text-black text-center">Add</p>
            </div>
          )} */}
        </div>
      )}
    </Draggable>
  );
};

export default InputsDragable;
