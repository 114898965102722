import { useNavigate } from "react-router-dom";
import "./style.css";

const SuccessPage = () => {
  return (
    <div className="parent-card">
      <div className="success-card">
        <div
          style={{
            borderRadius: "200px",
            paddingLeft: "45%",
            background: "#F8FAF5",
            margin: 0,
          }}
        >
          <i className="checkmark">✓</i>
        </div>
        <span style={{ color: "#000000" }}>
          <h1>Success</h1>
          <p>
            We received your purchase request;
            <br /> You will receive verification Email.
          </p>
        </span>
      </div>
    </div>
  );
};

export default SuccessPage;
