import axios from "axios";
import http from "./http-common";

const upload = (files: FileList, onUploadProgress: any): Promise<any> => {
  console.log("Files :", files);
  let formData = new FormData();

  let custom: any = [];
  Array.from(files).forEach((data: File) => {
    formData.append("documents", data);
    custom.push({
      attachmentId: 1,
      fileName: data.name,
      originalName: data.name,
    });
  });

  let userId = sessionStorage.getItem("userId");
  let companyId = sessionStorage.getItem("companyId");
  let ipAddress = sessionStorage.getItem("ipAddress");

  formData.append("userId", userId?.toString()!);
  formData.append("companyId", companyId?.toString()!);
  formData.append("ipAddress", ipAddress?.toString()!);
  formData.append("eSignFileDetails", JSON.stringify(custom));
  formData.append(
    "emailId",
    JSON.stringify(sessionStorage.getItem("userEmail"))
  );

  const baseURL = process.env.REACT_APP_API_URL;
  const headerKey = process.env.REACT_APP_API_HEADER_KEY;

  return http.post(`${baseURL}/upload/doc/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      key: `${headerKey}`,
      Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
    onUploadProgress,
  });
};

const getFiles = (): Promise<any> => {
  return http.get("/files");
};

const DocumentUploadService = {
  upload,
  getFiles,
};

export default DocumentUploadService;
