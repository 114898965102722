// MainModal.js
import React, { useState } from "react";
import AgreementModal from "./AgreementModal";

const modalOverlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
};

const modalContentStyle = {
  background: "white",
  padding: "20px",
  borderRadius: "5px",
  position: "relative",
};

const closeButtonStyle = {
  position: "absolute",
  top: "10px",
  right: "10px",
  background: "transparent",
  border: "none",
  cursor: "pointer",
};

const buttonStyle = {
  marginTop: "10px",
  padding: "10px 20px",
  border: "none",
  borderRadius: "5px",
  background: "#007bff",
  color: "white",
  cursor: "pointer",
};

const buttonDisabledStyle = {
  ...buttonStyle,
  opacity: 0.5,
  cursor: "not-allowed",
};

const linkStyle = {
  color: "blue",
  textDecoration: "underline",
  cursor: "pointer",
};

const MainModal = ({ isOpen, onClose }) => {
  const [isAgreementModalOpen, setAgreementModalOpen] = useState(false);
  const [isChecked, setChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setChecked(e.target.checked);
  };

  const handleOkClick = () => {
    if (isChecked) {
      onClose();
    }
  };

  return (
    <>
      {isOpen && (
        <div style={modalOverlayStyle}>
          <div style={modalContentStyle}>
            <h2>Agreement</h2>
            <div>
              <label>
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <a
                  href="#"
                  style={linkStyle}
                  onClick={() => setAgreementModalOpen(true)}
                >
                  View Agreement
                </a>
              </label>
              <button
                style={isChecked ? buttonStyle : buttonDisabledStyle}
                onClick={handleOkClick}
                disabled={!isChecked}
              >
                OK
              </button>
            </div>
            <button style={closeButtonStyle} onClick={onClose}>
              Close
            </button>
          </div>
          {isAgreementModalOpen && (
            <AgreementModal
              isOpen={isAgreementModalOpen}
              onClose={() => setAgreementModalOpen(false)}
            />
          )}
        </div>
      )}
    </>
  );
};

export default MainModal;
