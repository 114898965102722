import moment from "moment";
import React, { useContext, useEffect } from "react";
import { AiOutlineCaretDown } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import ManageContext from "../../context/manage/manage.context";
import styles from "../../styles/Manage.module.css";
import DataTable, { createTheme } from "react-data-table-component";
import { saveAs } from "file-saver";
import {
  FaFileDownload,
  FaFileSignature,
  FaInfo,
  FaRedo,
  FaTrash,
} from "react-icons/fa";

interface AllDocumentsTableProps {
  allDocs: any[];
}

export const AllDocumentsTable: React.FC<AllDocumentsTableProps> = ({
  allDocs,
}) => {
  const navigate = useNavigate();
  const { setCurrentDocumentHeaderId } = useContext(ManageContext);
  const [allDocumentData, setAllDocumentData] = React.useState<any[]>([]);

  const baseURL = process.env.REACT_APP_API_URL;
  const headerKey = process.env.REACT_APP_API_HEADER_KEY;

  const darkTheme: any = {
    title: {
      fontSize: "18px",
      color: "red",
    },
    header: {
      fontSize: "16px",
      fontColor: "red",
      backgroundColor: "#363640",
    },
    headCells: {
      style: {
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
    rows: {
      style: {
        fontSize: "14px",
      },
    },
  };

  createTheme("solarized", {
    background: {
      default: "transparent",
    },
  });

  useEffect(() => {
    const allDocumentData = allDocs?.map((doc) => ({
      Subject: doc?.envelopeName,
      TotalDocument: doc?.totalDocuments,
      SentOn: doc?.sentOnMail,
      Status: doc?.signerStatus,
      LastChanges: doc?.lastUpdated,
      HeaderId: doc?.documentHeaderId,
      senderStatus: doc?.senderStatus,
    }));
    setAllDocumentData(allDocumentData);
  }, [allDocs]);

  const caseSort = (rowA: any, rowB: any) => {
    const a = rowA.LastChanges;
    const b = rowB.LastChanges;
    return a > b ? 1 : b > a ? -1 : 0;
  };

  const handleResendDocument = (documentHeaderId: string) => {
    console.log(`Resending document with Header ID: ${documentHeaderId}`);
    // Implement the logic to resend the document
  };

  const handleDeleteDocument = (documentHeaderId: string) => {
    console.log(`Resending document with Header ID: ${documentHeaderId}`);
    // Implement the logic to resend the document
  };

  const handleSign = (documentHeaderId: string) => {
    setCurrentDocumentHeaderId(documentHeaderId);
    navigate("/documentDetails");
  };

  const handleSignPackageInfo = (documentHeaderId: string) => {
    setCurrentDocumentHeaderId(documentHeaderId);
    navigate("/documentDetails?id=3");
  };

  const getAuditData = async (documentHeaderId: string) => {
    try {
      const res = await fetch(
        `${baseURL}/audit/get-audit-document-wise?userId=${sessionStorage.getItem(
          "userId"
        )}&companyId=${sessionStorage.getItem(
          "companyId"
        )}&documentHeaderId=${documentHeaderId}`,
        {
          headers: {
            key: `${headerKey}`,
            Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
          },
        }
      );
      const blob = await res.blob();
      saveAs(blob, "auditTrail.pdf");
    } catch (err) {
      console.error("Failed to download audit report", err);
    }
  };

  const getSignedDoc = async (documentHeaderId: string) => {
    try {
      const res = await fetch(
        `${baseURL}/audit/get-completed-document?userId=${sessionStorage.getItem(
          "userId"
        )}&companyId=${sessionStorage.getItem(
          "companyId"
        )}&documentHeaderId=${documentHeaderId}`,
        {
          headers: {
            key: `${headerKey}`,
            Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
          },
        }
      );
      const blob = await res.blob();
      saveAs(blob, "signedDoc.zip");
    } catch (err) {
      console.error("Failed to download signed document", err);
    }
  };

  const columns = [
    {
      name: "SUBJECT",
      selector: (row: any) => row.Subject,
      sortable: true,
      width: "15%",
      style: { fontSize: "14px", fontWeight: "bold" },
      sortFunction: caseSort,
    },
    {
      name: "COUNT",
      selector: (row: any) => row.TotalDocument,
      sortable: true,
      center: true,
      width: "10%",
      sortFunction: caseSort,
    },
    {
      name: "SENT ON",
      selector: (row: any) =>
        row.SentOn !== "" ? moment(row.SentOn).format("D MMM YY, h:mm A") : "",
      sortable: true,
      center: true,
      width: "15%",
      sortFunction: caseSort,
    },
    {
      name: "RESEND",
      cell: (row: any) =>
        // row.Status !== "completed" && row.senderStatus !== "completed" ? (
        row.Status ? (
          <FaRedo
            style={{ cursor: "pointer" }}
            color="black"
            title="Resend Document"
            size={15}
            onClick={() => handleResendDocument(row.HeaderId)}
          />
        ) : (
          ""
        ),
      center: true,
      width: "10%",
    },
    {
      name: "STATUS",
      selector: (row: any) => row.Status,
      sortable: true,
      center: true,
      width: "10%",
      sortFunction: caseSort,
    },
    {
      name: "LAST CHANGED",
      selector: (row: any) =>
        moment(row.LastChanges).format("D MMM YY, h:mm A"),
      sortable: true,
      center: true,
      width: "15%",
      sortFunction: caseSort,
    },
    {
      name: "AUDIT",
      cell: (row: any) =>
        row.Status === "completed" ? (
          <FaFileDownload
            style={{ cursor: "pointer" }}
            color="black"
            title="Download Audit Report"
            size={25}
            onClick={() => getAuditData(row.HeaderId)}
          />
        ) : (
          ""
        ),
      center: true,
      width: "8%",
    },
    {
      name: "SIGNED",
      cell: (row: any) =>
        row.Status === "completed" ? (
          <FaFileDownload
            style={{ cursor: "pointer" }}
            color="black"
            title="Download Signed Document"
            size={25}
            onClick={() => getSignedDoc(row.HeaderId)}
          />
        ) : (
          ""
        ),
      center: true,
      width: "8%",
    },
    {
      name: "DOC + AUDIT",
      cell: (row: any) =>
        row.Status === "completed" ? (
          <FaFileDownload
            style={{ cursor: "pointer" }}
            color="black"
            title="Download Document & Audit"
            size={25}
            onClick={() => {
              getSignedDoc(row.HeaderId);
              getAuditData(row.HeaderId);
            }}
          />
        ) : (
          ""
        ),
      center: true,
      width: "13%",
    },
    {
      name: "ACTIONS",
      cell: (row: any) =>
        row.Status !== "completed" && row.senderStatus !== "completed" ? (
          <div className={styles.btn} onClick={() => handleSign(row.HeaderId)}>
            Start
          </div>
        ) : row.Status !== "completed" && row.senderStatus === "completed" ? (
          <div
            className={styles.btn}
            onClick={() => handleSignPackageInfo(row.HeaderId)}
          >
            Package Info
          </div>
        ) : (
          ""
        ),
      center: true,
      width: "10%",
    },

    {
      name: "DELETE",
      cell: (row: any) =>
        // row.Status !== "completed" && row.senderStatus !== "completed" ? (
        row.Status ? (
          <FaTrash
            style={{ cursor: "pointer" }}
            color="black"
            title="Delete Document"
            size={15}
            onClick={() => handleDeleteDocument(row.HeaderId)}
          />
        ) : (
          ""
        ),
      center: true,
      width: "8%",
    },
  ];

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto shadow-md sm:rounded-lg">
        <div className="inline-block min-w-full align-middle">
          <div className="h-[80vh] overflow-scroll pb-10">
            <DataTable
              columns={columns}
              data={allDocumentData}
              theme="solarized"
              defaultSortAsc={false}
              customStyles={darkTheme}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
