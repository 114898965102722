import { LinearProgress } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import DocumentDetailsContext from "../../context/DocumentsDetails/documentDetails.context";
import ManageContext from "../../context/manage/manage.context";
import { fileTypes } from "../fileuploader/FileUpload";
import FileView from "../fileuploader/FileView";

import { DocumentNavigator } from "./DocumentNavigator";

interface AddFilesProps {
  type: number;
  setType: any;
}

export const AddDocuments: React.FC<AddFilesProps> = ({ type, setType }) => {
  const [files, setFiles] = useState<any[]>([]);
  const [onlySigner, setOnlySigner] = useState<boolean>(false);
  const {
    documentDetails,
    loading,
    getDocumentDetails,
    currentDocumentHeaderId,
  } = useContext<any>(ManageContext);
  const { getRecipientSavedState, getPreprationPageDetails, deleteDocument } =
    useContext<any>(DocumentDetailsContext);

  useEffect(() => {
    let userId = sessionStorage.getItem("userId") || "";
    let companyId = sessionStorage.getItem("companyId") || "";
    getDocumentDetails(userId, companyId, currentDocumentHeaderId);
    getRecipientSavedState();
    getPreprationPageDetails();
  }, []);

  const handleFileUpload = (
    event: { currentTarget: { files: any } },
    type?: string
  ) => {
    if (event.currentTarget) {
      // console.log([...event.currentTarget.files]);
      let fileArray: any[] = [];
      [...event.currentTarget.files].forEach((file) => {
        if (fileTypes.includes(file.name.split(".").pop())) {
          fileArray.push(file);
        } else {
        }
      });
      setFiles((prevState) => [...prevState, ...fileArray]);
    }
  };

  const handleDelete = async (id: any) => {
    console.log("DELETE DOCUMENT", deleteDocument);
    await deleteDocument(id);
    let userId = sessionStorage.getItem("userId") || "";
    let companyId = sessionStorage.getItem("companyId") || "";
    getDocumentDetails(userId, companyId, currentDocumentHeaderId);
  };

  return (
    <>
      <DocumentNavigator type={type} setType={setType} />
      <div className="p-4 w-2/3 mx-auto">
        <div className="flex items-center justify-center flex-col w-full border-secondary bg-primary-900 p-4 rounded-5 border-opacity-70 relative pb-6 drop-shadow-md">
          {loading ? (
            <div className="w-full">
              <LinearProgress
                color="inherit"
                style={{
                  backgroundColor: "red",
                }}
              />
            </div>
          ) : (
            <FileView
              data={documentDetails}
              showDelete={true}
              handleDelete={handleDelete}
            />
          )}

          {/* <FileUpload
          multiple={true}
          DisplayText={fileText}
          format={true}
          files={files}
          handleFileUpload={handleFileUpload}
          setFiles={setFiles}
        /> */}
          {/* <div className="absolute bottom-0 right-0">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={onlySigner}
                  onChange={(e) => setOnlySigner(e.target.checked)}
                  disabled
                />
              }
              label="I am the only Signer"
              className="text-black"
              />
          </FormGroup>
        </div> */}
        </div>
      </div>
    </>
  );
};
const fileText = (
  <p className="text-black opacity-70 font-semibold text-3xl">
    Drag and Drop Files or <span className="text-red"> Browser Documents</span>
  </p>
);
