import React from "react";
import { signActions } from "../helpers/Constants";

interface SideBarProps {
  children?: React.ReactNode;
  type: number;
  setType: any;
}

const Documentdetailsidebar: React.FC<SideBarProps> = ({
  type,
  setType,
  children,
}) => {
  const handleChange = (type: number) => {
    setType(type);
  };

  return (
    <div className="flex flex-no-wrap h-full">
      <div className="w-1/6 sm:relative bg-primary-900 shadow md:h-full flex-col hidden sm:flex py-4 h-full pl-4">
        <div className="mb-4">
          <h5 className="opacity-90 text-lg text-black">
            A document for signature
          </h5>
        </div>

        <div className="pl-4">
          <ol className="relative dark:border-gray-700">
            <li
              className="mb-1 ml-2 cursor-pointer opacity-80 hover:opacity-100 text-black"
              onClick={() => handleChange(0)}
            >
              <span
                className={`text-xl ml-2 ${
                  signActions[type] === "Add Documents" && "text-green"
                }`}
              >
                Add files
              </span>
            </li>
            <li
              className="mb-1 ml-2 cursor-pointer opacity-80 hover:opacity-100 text-black"
              onClick={() => handleChange(1)}
            >
              <span
                className={`text-xl ml-2 ${
                  signActions[type] === "Add Recipients" && "text-green"
                }`}
              >
                Select
              </span>
            </li>
            <li
              className="mb-1 ml-2 cursor-pointer opacity-80 hover:opacity-100 text-black"
              onClick={() => handleChange(2)}
            >
              <span
                className={`text-xl ml-2 ${
                  signActions[type] === "Prepare" && "text-green"
                }`}
              >
                Prepare
              </span>
            </li>
            <li
              className="mb-1 ml-2 cursor-pointer opacity-80 hover:opacity-100 text-black"
              onClick={() => handleChange(3)}
            >
              <span
                className={`text-xl ml-2 ${
                  signActions[type] === "Review" && "text-green"
                }`}
              >
                Review
              </span>
            </li>
          </ol>
        </div>
      </div>
      {children}
    </div>
  );
};

export default Documentdetailsidebar;
