import { CircularProgress } from "@mui/material";
import axios from "axios";
import { publicIpv4 } from "public-ip";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import SignatureContext from "../context/signature/Signature.context";
import { Headers } from "./layouts/Headers";
import { UploadSectionMain } from "./Main/UploadSection.Main";
import { SignaturePopup } from "./signature/SignaturePopup";
import { dataFont } from "./signature/Tabs/SignatureSelect";
import { CookieConsentPopup } from "./CookieConsentModal";

interface MainProps {}
const baseURL = process.env.REACT_APP_API_URL;
const headerKey = process.env.REACT_APP_API_HEADER_KEY;

export const Main: React.FC<MainProps> = ({}) => {
  const [modal, setModal] = useState<boolean>(false);
  const { signImage, signText, fontId, loading, getUserInfo, getSignImage } =
    useContext<any>(SignatureContext);
  const [waiting, setWaiting] = useState<any>(0);
  const [expire, setExpire] = useState<any>(0);
  const [complete, setComplete] = useState<any>(0);

  const [showCookiePopup, setShowCookiePopup] = useState(false);

  const handleAcceptCookies = () => {
    // Handle acceptance logic (e.g., setting a cookie)
    console.log("Cookies Accepted");
    setShowCookiePopup(false);
  };

  const getIpAddress = async () => {
    const res = await publicIpv4();
    sessionStorage.setItem("ipAddress", res);
  };

  const getAllPendingDocuments = async (type: string) => {
    axios
      .get(
        `${baseURL}/upload/get-pending-documents-header?userId=${sessionStorage.getItem(
          "userId"
        )}&companyId=${sessionStorage.getItem("companyId")}&status=${type}`,
        {
          headers: {
            key: `${headerKey}`,
            Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
          },
        }
      )
      .then((res) => {
        switch (type) {
          case "pending":
            setWaiting(res.data?.length || 0);
            break;
          case "expiresoon":
            setExpire(res.data?.length || 0);
            break;
          case "completed":
            setComplete(res.data?.length || 0);
            break;
          default:
            break;
        }
      });
  };

  const setShowModal = (value: boolean) => {
    setModal(value);
  };
  // useEffect(() => {
  //   getAllPendingDocuments("pending");
  //   getAllPendingDocuments("expiresoon");
  //   getAllPendingDocuments("complete");
  // }, []);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let companyId = searchParams.get("companyId");
    let userId = searchParams.get("userId");
    if (companyId && userId) {
      sessionStorage.setItem("companyId", companyId);
      sessionStorage.setItem("userId", userId);
    }

    getAllPendingDocuments("pending");
    getAllPendingDocuments("expiresoon");
    getAllPendingDocuments("completed");

    getSignImage();
    getIpAddress();
    getUserInfo();
  }, []);

  const navigate = useNavigate();
  return (
    <>
      <Headers />
      <div className="py-2 px-4 lg:px-15">
        <div>
          <h3 className="text-black opacity-80">
            Welcome {sessionStorage.getItem("userName")}
          </h3>
          <div>
            <h3 className="text-black opacity-80">
              Hope you are having a good day.
            </h3>
          </div>
        </div>

        <div className="grid grid-flow-row-dense grid-cols-4 grid-rows-1 auto-cols-max mt-4 home-cards">
          <div className="max-w-sm bg-primary-1 rounded-lg  border border-primary-200 signature-css shadow-outlineSm my-2 overflow-hidden relative">
            {loading ? (
              <div className="flex items-center justify-center px-2 py-1.5">
                <CircularProgress />
              </div>
            ) : (
              <div className="flex items-start justify-between flex-col px-2 py-1.5">
                <p className="font-normal text-lg text-black hover:text-primary-800 text-center ">
                  My Signature
                </p>
                {signImage ? (
                  <img src={signImage} className="h-8 w-full" />
                ) : (
                  <p
                    className={`w-auto text-3xl text-black ${
                      dataFont.find((item) => item.id === fontId)?.fontClass
                    } `}
                  >
                    {signText}
                  </p>
                )}
              </div>
            )}
            {/* <div className="flex items-center justify-between px-2 py-1.5">
              <p className="font-normal text-xs text-black hover:text-primary-800 text-center">
                My Initials
              </p>
              <p className="font-normal text-sm text-black hover:text-primary-800 text-center">
                Aditya
              </p>
            </div> */}
            {!loading && (
              <div
                className="cursor-pointer absolute bottom-1 right-2"
                onClick={() => setModal(true)}
              >
                <div className="text-accent text-lg">Edit</div>
              </div>
            )}
          </div>
          <div className="max-w-sm bg-primary-1 rounded-lg  border border-primary-200 w-5/6 action-css my-2 mx-4 flex items-center justify-center flex-col">
            <p
              onClick={() => {
                navigate("/manage?type=pending");
              }}
              className="font-normal cursor-pointer px-1.5 hover:text-primary-900 text-center text-lg"
            >
              Actions Required
            </p>
            <span className="text-xl">{waiting + expire || "0"}</span>
          </div>
          {/* <div className="max-w-sm bg-primary-1 rounded-lg  border border-primary-200 shadow-outlineSm my-2 mx-4 flex items-center justify-center flex-col">
            <p className="font-normal p-1 text-black hover:text-primary-800 text-center">
              Waiting
            </p>
            <span className="text-black text-xl">{waiting || "0"}</span>
          </div> */}
          <div className="max-w-sm bg-primary-1 rounded-lg  border border-primary-200 expiring-css my-2 mx-4 flex items-center justify-center flex-col">
            <p
              onClick={() => {
                navigate("/manage?type=expiresoon");
              }}
              className="font-normal  cursor-pointer p-1 hover:text-primary-900 text-center"
            >
              Expiring Soon
            </p>
            <span className=" text-xl">{expire || "0"}</span>
          </div>
          <div className="max-w-sm bg-primary-1 rounded-lg  border border-primary-200 complete-css  my-2 mx-4 flex items-center justify-center flex-col">
            <p
              onClick={() => {
                navigate("/manage?type=completed");
              }}
              className="font-normal p-1 cursor-pointer hover:text-primary-900 text-center"
            >
              Complete
            </p>
            <span className=" text-xl">{complete || "0"}</span>
          </div>
        </div>

        <div style={{ cursor: "default" }} className="mt-4">
          <h3 className="text-black opacity-80">Get Started</h3>
        </div>

        <UploadSectionMain />

        {modal && <SignaturePopup setShowModal={setShowModal} modal={modal} />}

        {showCookiePopup && (
          <CookieConsentPopup
            modal={showCookiePopup}
            setShowModal={setShowCookiePopup}
            onAccept={handleAcceptCookies}
          />
        )}
      </div>
    </>
  );
};
