import React, { useState } from "react";
import {
  SiMicrosoftword,
  SiMicrosoftexcel,
  SiMicrosoftpowerpoint,
} from "react-icons/si";
import { AiFillFileText, AiOutlineFilePdf } from "react-icons/ai";
import { BsFillCameraVideoFill } from "react-icons/bs";
import { getFileType } from "./FileUpload";
import { MdVisibility, MdGraphicEq, MdDelete } from "react-icons/md";
import SingleFileView from "./SingleFileViewer";

interface FileViewProps {
  data: any[];
  showDelete: boolean;
  handleDelete?: (id: any) => void;
}

const FileView: React.FC<FileViewProps> = ({
  data,
  showDelete,
  handleDelete,
}) => {
  // console.log("data", data, getFileType(data[0]?.documentsOriginalName));

  return (
    <>
      {data.length > 0 ? (
        data.map((file: any) => {
          return (
            <SingleFileView
              file={file}
              showDelete={showDelete}
              handleDelete={handleDelete}
            />
          );
        })
      ) : (
        <div style={{ color: "black" }}>No document found</div>
      )}
    </>
  );
};

export default FileView;
