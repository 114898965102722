/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from "react";
import DocumentDetailsContext from "../../context/DocumentsDetails/documentDetails.context";
import InputsDragable from "../helpers/InputsSuggestion";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useSnackbar } from "notistack";
import { DocumentNavigator } from "./DocumentNavigator";
import { LinearProgress } from "@mui/material";
import { validateEmail } from "../helpers/Helpers";
import { AnyARecord } from "dns";

const grid = 1;
export const getRandomColor = () => {
  let bgColor =
    "#" + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6);
  bgColor = String(bgColor);
  return bgColor;
};
export const getSignColorByIndex = (index: number) => {
  const colorListArray = [
    "#265176",
    "#f76708",
    "#006666",
    "#7C446A",
    "#68A5F9",
    "#FF2DC6",
    "#F7AC08",
    "#DF0000",
    "#1B9D2C",
    "#633A00",
  ];

  return colorListArray[index % 10];
};

const getListStyle = (isDraggingOver: any) => ({
  padding: grid,
  width: "100%",
});

const reorder = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
export const getRandomIndex = (min: number = 10) => {
  return Math.floor(Math.random() * 100) + min;
};

interface SelectProps {
  type: number;
  setType: any;
}

export const SelectRecipients: React.FC<SelectProps> = ({ type, setType }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [signOrder, setSignOrder] = useState<boolean>(false);
  const [indexEd, setIndexEd] = useState<any>();

  const [isChecked, setIsChecked] = useState<boolean>(false);

  const [state, setState] = useState<any>({
    order: [
      {
        idx: 1,
        name: "",
        emailId: "",
        signerId: "",
      },
    ],
  });

  const {
    savedRecipientList,
    setRecipientList,
    deleteRecipient,
    getRecipientSavedState,
  } = useContext(DocumentDetailsContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [isEmptyData, setisEmptyData] = useState<boolean>(false);
  const [isUnsavedData, setIsUnsavedData] = useState<boolean>(false);
  const [getDocId, setgetDocId] = useState(
    localStorage.getItem("currentDocumentHeaderId")
  );
  const [checkFlag, setcheckFlag] = useState<boolean>();
  const stateRef = useRef(state);
  // state.current = state;
  const [newRecipientList, setNewRecipientList] = useState<any>({
    currentDocumentHeaderId: getDocId,
    order: [],
  });
  useEffect(() => {
    let list = [
      ...savedRecipientList.map((rec: any, idx: number) => ({
        id: rec.id,
        idx: rec.signingOrder,
        name: rec.name,
        emailId: rec.emailId,
        colorCode: rec.colorCode,
      })),
    ];

    list = list.sort((a: any, b: any) => (a.idx < b.idx ? -1 : 1));
    let ids = list?.map((object: any) => {
      return object.idx;
    });
    if (!ids || ids.length < 1) {
      ids = [0];
    }
    const max = Math.max(...ids);
    // list.push({
    //   idx: max + 1,
    //   name: "",
    //   emailId: "",
    //   color: getRandomColor(),
    // });
    let newlist = [
      ...list,
      ...state.order?.filter(
        (data: any) =>
          !data.id && data?.idx !== 1 && data.name !== "" && data.emaiId !== ""
      ),
    ];
    setState({
      order: newlist,
    });

    setNewRecipientList({
      ...newRecipientList,
      order: newlist,
    });
    if (savedRecipientList.length > 0) {
      setIsUpdate(true);
    }
    setLoading(false);
  }, [savedRecipientList]);

  console.log("savedRecipientList", savedRecipientList);
  useEffect(() => {
    if (!localStorage.getItem("setSigningOrderCheckBox"))
      localStorage.setItem(
        "setSigningOrderCheckBox",
        JSON.stringify({ docId: getDocId, value: isChecked })
      );
    else {
      let flag = JSON.parse(
        localStorage.getItem("setSigningOrderCheckBox") || ""
      );
      console.log("Flag", flag);
      let val = flag?.value;
      if (flag.docId == localStorage.getItem("currentDocumentHeaderId") || "")
        setIsChecked(val);
    }

    return () => {
      getRecipientSavedState();
    };
  }, []);

  useEffect(() => {
    const R_list: any = JSON.parse(
      sessionStorage.getItem("RecipientsList") || "[]"
    );
    console.log("RecipientsList R_list ", R_list);
    let currentdata = R_list[getDocId || ""];

    if (currentdata && currentdata.length) {
      let ids = currentdata?.map((object: any) => {
        return object.idx;
      });
      if (!ids || ids.length == 0) ids = [0];
      const max = Math.max(...ids);

      // currentdata.push({
      //   idx: max + 1,
      //   name: "",
      //   emailId: "",
      //   color: getRandomColor(),
      // });
      // let unqiuearray =  currentdata.filter((a:any, i:any) => currentdata.findIndex((s:any) => a.emailId === s.emailId) === i)

      // console.log("currentdata",unqiuearray)
      setState({
        ...state,
        order: currentdata,
      });
      setNewRecipientList({
        ...newRecipientList,
        order: currentdata,
      });

      console.log(" inside data of if", R_list[getDocId || ""]);
    }
  }, []);

  useEffect(() => {
    if (state.order.length == 0) {
      setState({
        order: [
          {
            idx: 1,
            name: "",
            emailId: "",
            signerId: "",
          },
        ],
      });
    }

    return () => {
      saveListInSessionStorage();
    };
  }, [state]);

  // useEffect(() => {
  //   if (savedRecipientList.length + 1 < state.order.length) {
  //     setIsUnsavedData(true);
  //   }
  // }, [savedRecipientList, state]);
  console.log("order,", state);

  const addRecipient = (data: any, index: any) => {
    console.log("jcbckcjbndsc", data, index);
    if (data.name === "" || !data.name || data.emaiId === "" || !data.emailId) {
      enqueueSnackbar("Please give both name and email first!", {
        variant: "warning",
      });
      return false;
    }
    if (
      state.order.length >= 1 &&
      state.order.filter((item: any) => item.emailId === data.emailId).length >=
        1
    ) {
      enqueueSnackbar("Same entry found!", {
        variant: "warning",
      });

      return false;
    }

    if (!validateEmail(data.emailId)) {
      enqueueSnackbar("Please enter valid email!", {
        variant: "warning",
      });
      return false;
    }

    // sessionStorage.setItem("RecipientsList", JSON.stringify());
    let newOrder = state.order;
    console.log("order", newOrder);

    /*  newOrder[index].name = data.name;
    newOrder[index].emailId = data.emailId;  */

    let newRecipient = newRecipientList;

    /* newRecipient.order[index].name = data.name;
    newRecipient.order[index].emailId = data.emailId; */

    const R_list: any = JSON.parse(
      sessionStorage.getItem("RecipientsList") || "[]"
    );
    /*  const MAXiD = R_list[getDocId||""]?.map((object: any) => {
      return object.idx;
    });
    const maxIDX = Math.max(...MAXiD);
 */
    if (index >= 0) {
      if (newRecipient.order[index]) {
        newRecipient.order[index].name = data.name;
        newRecipient.order[index].emailId = data.emailId;
      } else {
        let ids = newRecipient.order?.map((object: any) => {
          return object.idx;
        });
        if (!ids || ids.length == 0) ids = [0];
        const max = Math.max(...ids);
        newRecipient.order[index] = { ...data, idx: max + 1 };
        // if(!newOrder[index])
        //    newOrder[index]={...data,idx:index}
      }
    }
    console.log("newRecipient", newRecipient);

    let docId = newRecipient.currentDocumentHeaderId || "";
    let RecipientObj = newRecipient.order;

    let newobject: any = JSON.parse(
      sessionStorage.getItem("RecipientsList") || "{}"
    );
    let newarry = newobject[docId];
    console.log("newarry", newarry);

    if (!newarry) {
      // let unqiuearray =  RecipientObj.filter((a:any , i:any) => RecipientObj.findIndex((s:any) => a.emailId === s.emailId) === i)
      newobject[docId] = RecipientObj;
    } else {
      let compliearry = [...newarry, ...RecipientObj];
      // let unqiuearray = [...new Set(compliearry)]
      let unqiuearray = compliearry.filter(
        (a, i) => compliearry.findIndex((s) => a.emailId === s.emailId) === i
      );
      console.log("unqiuearray", unqiuearray);
      newobject[docId] = unqiuearray;
    }

    let filterobj = newobject;

    filterobj[docId] = newobject[docId]?.filter(
      (item: any) => item.emailId !== "" && item.name !== ""
    );
    let currentdata = newobject[docId];
    /*   if(currentdata){
      newobject[docId]=RecipientObj
    }else{
      
    } */
    // newobject.push(newRecipient)
    // newobject.push({...newRecipient})

    sessionStorage.setItem("RecipientsList", JSON.stringify(filterobj));

    // setState({
    //   ...state,
    //   order: [...newOrder],
    // });
    let ids = newRecipient.order?.map((object: any) => {
      return object.idx;
    });
    if (!ids || ids.length == 0) ids = [0];
    const max = Math.max(...ids);

    setState({
      ...state,
      order: [
        ...newRecipient.order,
        {
          idx: max + 1,
          name: "",
          emailId: "",
        },
      ],
    });

    setState({
      order: [],
    });

    setisEmptyData(true);
    return true;
  };
  const addNewRecipient = () => {
    let newOrder = state.order;
    console.log("order", newOrder);

    let newRecipient = newRecipientList;

    const R_list: any = JSON.parse(
      sessionStorage.getItem("RecipientsList") || "[]"
    );

    console.log("newRecipient", newRecipient);

    let docId = newRecipient.currentDocumentHeaderId || "";

    let newobject: any = JSON.parse(
      sessionStorage.getItem("RecipientsList") || "{}"
    );

    let filterobj = newobject;
    console.log("obj", newobject);

    filterobj[docId] = newobject[docId]?.filter(
      (item: any) => item.emailId !== "" && item.name !== ""
    );

    sessionStorage.setItem("RecipientsList", JSON.stringify(filterobj));

    let ids = state.order?.map((object: any) => {
      return object.idx;
    });
    if (!ids || ids?.length == 0) ids = [0];
    const max = Math.max(...ids);

    setState({
      ...state,
      order: [
        ...state.order,
        {
          idx: max + 1,
          name: "",
          emailId: "",
        },
      ],
    });

    setisEmptyData(true);
    return true;
  };
  const saveListInSessionStorage = () => {
    let docId = newRecipientList.currentDocumentHeaderId || "";
    let newobject: any = JSON.parse(
      sessionStorage.getItem("RecipientsList") || "{}"
    );
    let filtered = state.order?.filter(
      (item: any) => item.emailId !== "" || item.name !== ""
    );
    // console.log("State",state.order,filtered)
    newobject[docId] = filtered;
    console.log("abdObj", newobject);

    sessionStorage.setItem("RecipientsList", JSON.stringify(newobject));
  };
  console.log("newRecipientList", newRecipientList);

  const updateDocument = (data: any, index: any) => {
    console.log(" update doctor", { data, index });
    let newOrder = state.order;

    if (
      newOrder[index].name == data.name &&
      newOrder[index].emailId == data.emailId
    ) {
      // setIsUnsavedData(false);
    } else {
      newOrder[index].name = data.name;
      newOrder[index].emailId = data.emailId;
      newOrder[index].signerId = data.signerId;
      setState({
        ...state,
        order: [...newOrder],
      });
      setIsUnsavedData(true);
    }
  };

  console.log("state managemnet ", state);
  const handleCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSignOrder(e.target.checked);
  };

  function onDragEnd(result: any) {
    console.log(result);
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const order: any = reorder(
      state.order,
      result.source.index,
      result.destination.index
    );

    setState({ order });
  }
  const getCorrectSequence = (list: any[]) => {
    let ids = list?.map((object: any) => {
      return object.signingOrder;
    });
    if (!ids || ids.length < 1) {
      ids = [0];
    }
    let min = Math.min(...ids);
    let max = Math.max(...ids);

    let num = -1;
    for (let i = min; i <= max; i++) {
      if (ids.findIndex((val) => val === i) < 0) {
        console.log("if part ", i);
        num = i;
        break;
      }
    }

    if (num !== -1) {
      let or = num - 1;
      console.log("or", or);

      for (let i = 0; i < list.length; i++) {
        if (list[i].signingOrder >= or) {
          console.log("list[i].signingOrder", list[i].signingOrder);
          list[i].signingOrder = or++;
        }
      }
    }
    return list;
  };

  const saveOrder = () => {
    let newData = state.order.map((obj: any, index: number) => ({
      signingOrder: obj.idx,
      emailId: obj.emailId,
      name: obj.name,
      id: obj?.id,
      signerId: obj?.signerId,
      colorCode: obj?.colorCode || getSignColorByIndex(index),
    }));
    if (!isCorrectSequence(state.order)) {
      // console.log("getcorrectsequence",getCorrectSequence(newData))

      newData = getCorrectSequence(newData);
      console.log("newData defined: ", newData);
      // enqueueSnackbar("Signing order is not in sequence", {
      //   variant: "warning",
      // });
    }
    let filterednewData: any = [];
    let emptyData = newData.filter(
      (item: any) => item.emailId == "" || item.name == ""
    );
    if (emptyData.length >= 1) {
      enqueueSnackbar("email and name can't be empty", {
        variant: "warning",
      });
      return false;
    }
    newData = newData.filter(
      (item: any) => item?.id || (item.emailId !== "" && item.name !== "")
    );

    // let emailArr = newData.map((item: any) => {
    //   return item.emailId;
    // });
    // let isDuplicate = emailArr.some((email: string, idx: number) => {
    //   return emailArr.indexOf(email) !== idx;
    // });
    // if (isDuplicate) {
    //   enqueueSnackbar("email already exist", {
    //     variant: "warning",
    //   });
    //   return;
    // }
    for (let [i, x] of newData?.entries()) {
      console.log("new vdfhvbdsuf", newData);
      if (
        x.emailId &&
        validateEmail(x.emailId) &&
        x.emailId !== "" &&
        x.name !== ""
      ) {
        filterednewData.push(x);
      } else {
        enqueueSnackbar("name or email invalid/empty", {
          variant: "warning",
        });
        return false;
      }
    }

    // return false;
    filterednewData.forEach((x: any) => {
      if (
        indexEd?.value.emaiId === x.emaiId &&
        indexEd?.value.name === x.name
      ) {
        // console.log(indexEd)
        // x.signingOrder = parseInt(indexEd.indexValue);
      }
    });

    console.log("Result >>>", filterednewData.length, newData);
    if (filterednewData.length !== 0) {
      setisEmptyData(true);
      setRecipientList(filterednewData, isChecked);
      setIsUnsavedData(false);

      let currenetdobj = JSON.parse(
        sessionStorage.getItem("RecipientsList") || ""
      );

      delete currenetdobj["getDocId" || ""];

      sessionStorage.setItem("RecipientsList", JSON.stringify(currenetdobj));
      localStorage.setItem(
        "setSigningOrderCheckBox",
        JSON.stringify({ docId: getDocId, value: isChecked })
      );
    } else {
      enqueueSnackbar("Please Enter Data", {
        variant: "warning",
      });
      return false;
    }
    setState({
      order: [],
    });
    return true;
    // setTimeout(() => {
    //   getRecipientSavedState();
    // }, 2000);
  };

  const isCorrectSequence = (list: any[]) => {
    let ids = list?.map((object: any) => {
      return object.idx;
    });
    if (!ids || ids.length < 1) {
      ids = [0];
    }
    let min = Math.min(...ids);
    let max = Math.max(...ids);
    for (let i = min; i <= max; i++) {
      if (ids.findIndex((val) => val === i) < 0) {
        return false;
      }
    }

    return true;
  };
  const handleOnChange = (e: any) => {
    setIsChecked(!isChecked);
  };

  const pullData = (data: any) => {
    if (data) {
      setIndexEd(data);
    }

    let list = state.order;
    // list = list.filter((item: any) => item.emaiId !== "" && item.name !== "");

    // list = reorder(list, data.index, parseInt(data.indexValue) - 1);
    let newOrder = parseInt(data.indexValue);
    let index = parseInt(data.index);
    if (!isNaN(index) && !isNaN(newOrder)) {
      list[index].idx = newOrder;
    }
    list = list.sort((a: any, b: any) => (a.idx < b.idx ? -1 : 1));
    console.log("list", list, data);
    // list.push({
    //   idx: -1,
    //   name: "",
    //   emailId: "",
    // });
    setState({
      ...state,
      order: list,
    });
  };

  const removeRecipient = async (index: any, id: any) => {
    if (id) {
      deleteRecipient(id);
    }
    // let currenetdobj;
    // let Newobj;
    // console.log("indexxxxxxxx remove ", index, id);
    // if (index == 0 || index) {
    //   currenetdobj = JSON.parse(sessionStorage.getItem("RecipientsList") || "");
    //   if (currenetdobj[getDocId || ""] && currenetdobj[getDocId || ""][index])
    //     delete currenetdobj[getDocId || ""][index];

    //   Newobj = currenetdobj;
    //   Newobj[getDocId || ""] = currenetdobj[getDocId || ""]?.filter(
    //     (item: any) => item !==null
    //   );
    //   console.log("[getDocId ||", Newobj);
    //   console.log();
    //   await sessionStorage.setItem("RecipientsList", JSON.stringify(Newobj));
    //   console.log("Newobj", Newobj);

    //   const R_list: any = await JSON.parse(
    //     sessionStorage.getItem("RecipientsList") || "[]"
    //   );
    //   console.log("RecipientsList R_list ", R_list);
    //   let currentdata = R_list[getDocId || ""];

    //   if (currentdata) {
    //     const ids = currentdata?.map((object: any) => {
    //       return object.idx;
    //     });
    //     const max = Math.max(...ids);

    //     currentdata.push({
    //       idx: max + 1,
    //       name: "",
    //       emailId: "",
    //       color: getRandomColor(),
    //     });
    // let unqiuearray =  currentdata.filter((a:any, i:any) => currentdata.findIndex((s:any) => a.emailId === s.emailId) === i)

    // console.log("currentdata",unqiuearray)
    let filteredOrder = state.order.filter((o: any, i: any) => i !== index);
    setState({
      ...state,
      order: filteredOrder,
    });
    setNewRecipientList({
      ...newRecipientList,
      order: filteredOrder,
    });
    console.log("filteredOrder", filteredOrder);

    //console.log(" inside data of if", R_list[getDocId || ""]);
    // }
    /*  setNewRecipientList({
        ...newRecipientList,
        order: Newobj[index],
      }); */
    //  console.log("hjbdcajsbhcasjcabsc",currenetdobj[getDocId || ""].length)
    //  }

    // let newOrder = state.order;
    // console.log("newOrder", newOrder);
    // // let unqiuearray =  newOrder.filter((a:any) => a.idx !== index)

    // let filterednewData: any = newOrder.filter((o: any, i: any) => i !== index);
    // filterednewData = filterednewData.filter(
    //   (item: any) => item.emaiId !== "" && item.name !== ""
    // );
    // console.log({ filterednewData });

    // const ids = state.order?.map((object: any) => {
    //   return object.idx;
    // });
    // const max = Math.max(...ids);
    // setState({
    //   ...state,
    //   order: [
    //     ...filterednewData,
    //     {
    //       idx: max + 1,
    //       name: "",
    //       emailId: "",
    //     },
    //   ],
    // });
  };

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    ...draggableStyle,
    top: "auto !important",
    left: "auto !important",
  });

  return (
    <>
      <DocumentNavigator
        type={type}
        setType={setType}
        handleDataSave={saveOrder}
        isEmptyData={isEmptyData}
        orderArray={state.order}
        isUnsaved={isUnsavedData}
      />
      <div className="p-4 w-2/3 mx-auto">
        <div className="w-full border-secondary bg-primary-900 p-4 rounded-5 border-opacity-70 relative pb-6 drop-shadow-md">
          {loading ? (
            <div className="w-full">
              <LinearProgress
                color="inherit"
                style={{
                  backgroundColor: "red",
                }}
              />
            </div>
          ) : (
            <>
              <div className="absolute top-0 left-0 px-2 flex items-center justify-center pb-4">
                <p className="text-black mx-[2px] text-base p-2">
                  Set Signing Order
                </p>
                <input
                  type="checkbox"
                  id="topping"
                  name="topping"
                  value="setSigningOrder"
                  checked={isChecked}
                  onChange={handleOnChange}
                />
              </div>

              <div className="flex items-start flex-col justify-start mt-4 relative w-full">
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided: any, snapshot: any) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {state.order.map((value: any, idx: any) => {
                          //console.log("drag and drop", value, idx);
                          //   <Draggable
                          //   key={value.idx}
                          //   draggableId={String(value.idx)}
                          //   index={idx}
                          // >
                          //   {(provided, snapshot) => (
                          //     <div
                          //       className={`flex items-center justify-start w-full mt-4 relative h-10 custoumClass`}
                          //       ref={provided.innerRef}
                          //       {...provided.draggableProps}
                          //       {...provided.dragHandleProps}
                          //       style={getItemStyle(
                          //         snapshot.isDragging,
                          //         provided.draggableProps.style
                          //       )}
                          //     >
                          //       {/* <h1 style={{ color: "black" }}>
                          //         {value.name}
                          //       </h1> */}
                          //       <InputsDragable
                          //         key={idx}
                          //         index={idx}
                          //         addRecipient={addRecipient}
                          //         value={value}
                          //         isCheckedIndex={isChecked}
                          //         pullDataFunc={pullData}
                          //         updateDocument={updateDocument}
                          //         removeRecipient={removeRecipient}
                          //       />
                          //     </div>
                          //   )}
                          // </Draggable>
                          return (
                            <InputsDragable
                              key={value.id || value.idx}
                              index={idx}
                              addRecipient={addRecipient}
                              value={value}
                              isCheckedIndex={isChecked}
                              pullDataFunc={pullData}
                              updateDocument={updateDocument}
                              removeRecipient={removeRecipient}
                            />
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>

              <div
                className="w-8 bg-green rounded-lg cursor-pointer add-btn"
                onClick={() => {
                  // let res = addRecipient(data, index);
                  // setIsRemove(res);
                  // setIsAdded(true);
                  addNewRecipient();
                }}
              >
                <p className="px-[9px] py-[1px] text-center">Add</p>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
