import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import axios from "axios";

export const ResendEmailVerification: React.FC<{}> = ({}) => {
  const baseURL = process.env.REACT_APP_API_URL;
  const headerKey = process.env.REACT_APP_API_HEADER_KEY;

  const [searchParams] = useSearchParams();

  const resendVerification = async () => {
    let email = searchParams.get("email");

    return await fetch(`${baseURL}/auth/resendVerificationMail/${email}`, {
      method: "POST",
      headers: {
        key: `${headerKey}`,
      },
    })
      .then((res) => res.json())

      .catch((err) => {
        console.log({ err });
        // enqueueSnackbar("Something went wrong", { variant: "error" });
      });
  };

  return (
    <>
      <div className="py-1 px-4 lg:px-15">
        <div
          className="resendTab "
          style={{ backgroundColor: "white", boxShadow: "1px 1px 3px grey" }}
        >
          {/* <button
            className={styles.btn}
            >
             Start
            </button> */}
          <div className="">
            <h2 className="text-black  text-center opacity-80">
              Resend verification mail
            </h2>
          </div>

          <button
            className="BtnVerifyUser"
            onClick={() => resendVerification()}
          >
            Resend
          </button>
          <div className="redirectToLogin">
            <Link to="/login">
              <p style={{ color: "blue" }}>For login please click here!</p>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
