// AgreementModal.js
import React from "react";

const modalOverlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
};

const modalContentStyle = {
  background: "white",
  padding: "20px",
  borderRadius: "5px",
  position: "relative",
};

const closeButtonStyle = {
  position: "absolute",
  top: "10px",
  right: "10px",
  background: "transparent",
  border: "none",
  cursor: "pointer",
};

const AgreementModal = ({ isOpen, onClose }) => {
  return (
    <>
      {isOpen && (
        <div style={modalOverlayStyle}>
          <div style={modalContentStyle}>
            <h2>Agreement Details</h2>
            <p>Here are the details of the agreement...</p>
            <button style={closeButtonStyle} onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default AgreementModal;
