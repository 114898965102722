import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { Routing } from "./router/route";
import { SnackbarProvider } from "notistack";
import { Collapse } from "@mui/material";
import { SignatureProvider } from "./context/signature/Signature.context";
import { ManageProvider } from "./context/manage/manage.context";
import { DocumentDetailsProvider } from "./context/DocumentsDetails/documentDetails.context";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <SnackbarProvider
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          TransitionComponent={Collapse}
        >
          <SignatureProvider>
            <ManageProvider>
              <Routing />
            </ManageProvider>
          </SignatureProvider>
        </SnackbarProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
