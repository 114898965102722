import React, { useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./checkoutForm/checkoutForm";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

const Pay = () => {
  const pk = process.env.REACT_APP_STRIPE_PK;
  const stripePromise = loadStripe(pk!.toString());

  // const stripePromise = loadStripe(
  //   "pk_live_51MdlXuJjOBNtZqsY8o2yts9jr24typsqzYp3Xx2Frr4ux2vJx7FwYmeGC9RJDBwtZ7ZLIVoYVr8L36nulJA4pCPD008rcj05DQ"
  // );

  const [searchParams] = useSearchParams();
  const emailId = searchParams.get("email")!;
  const name = searchParams.get("name")!;

  const baseURL = process.env.REACT_APP_API_URL;
  const headerKey = process.env.REACT_APP_API_HEADER_KEY;

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    checkoutSession();
  }, []);

  const checkoutSession = async () => {
    const stripe = await stripePromise;
    const resp = await axios.get(
      `${baseURL}/pay/create-checkout-session?priceId=price_1MhCYRJjOBNtZqsY0rtN3nSC&emailId=${emailId}`
    );
    if (resp.data) {
      stripe?.redirectToCheckout({
        sessionId: resp.data.sessionId,
      });
    }
  };

  return (
    <div></div>
    // <Elements stripe={stripePromise}>
    //   <CheckoutForm />
    // </Elements>
  );
};

export default Pay;
