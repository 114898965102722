import { Divider } from "@mui/material";
import React, { useContext } from "react";
import { BiArrowBack, BiRightArrowAlt } from "react-icons/bi";
import { signActions } from "../helpers/Constants";
import { useSnackbar } from "notistack";
import DocumentDetailsContext from "../../context/DocumentsDetails/documentDetails.context";
import { useNavigate } from "react-router-dom";

interface DocumentNavigatorProps {
  type: number;
  setType: any;
  handleDataSave?: any;
  isEmptyData?: boolean;
  isUnsaved?: boolean;
  orderArray?: any;
  currentDocument?: any;
  recipient?: number;
  isAllRecipientAdded?: any;
  currentDocInfo?: any;
}

export const DocumentNavigator: React.FC<DocumentNavigatorProps> = ({
  type,
  setType,
  handleDataSave,
  isEmptyData,
  isUnsaved,
  orderArray,
  currentDocument,
  isAllRecipientAdded,
  currentDocInfo,
}) => {
  const { getPlaceholderData } = useContext<any>(DocumentDetailsContext);
  const navigate = useNavigate();
  const handleSave = async () => {
    console.log(
      "handleSave called. typeof handleDataSave: ",
      typeof handleDataSave
    );

    return handleDataSave && handleDataSave();
    // setTimeout(() => {
    //   getPlaceholderData(currentDocument?.id);
    // }, 6000);
  };

  console.log("rrr currentDocInfo", currentDocInfo);
  console.log("rrr cur", currentDocInfo?.documentList[0]?.status);
  console.log(
    "rrr innn",
    currentDocInfo?.documentPreferencesModel?.senderStatus
  );

  const { enqueueSnackbar } = useSnackbar();
  // console.log("order", orderArray);
  return (
    <>
      <div className="h-2 py-3 m-3 flex items-center justify-between w-full">
        <div className="flex items-center justify-between">
          {signActions[type] !== "Add Documents" && (
            <BiArrowBack
              onClick={() => setType(type - 1)}
              size={20}
              className="text-black mr-6 cursor-pointer"
              title="previous"
            />
          )}
          <h4 className="text-black opacity-75 font-normal">
            {signActions[type]}
          </h4>
        </div>

        <div className="flex items-center justify-between px-4">
          <div
            className="svbtn cursor-pointer"
            onClick={async () => {
              await handleSave();
              navigate(`/manage`);
            }}
          >
            <p className="px-[8px] py-[2px]">Save & Close</p>
          </div>
          {signActions[type] !== "Review" && (
            <button
              // disabled={isUnsaved || orderArray?.length < 2}
              disabled={orderArray?.length < 1}
              className="rounded-lg bg-green cursor-pointer px-[10px] py-[3px]"
              onClick={async () => {
                console.log("Click");
                if (signActions[type] == "Prepare") {
                  // console.log("before await")
                  await handleSave();
                  //  console.log("after await")
                  if (await isAllRecipientAdded()) {
                    //   enqueueSnackbar("sign for all recipient present", { variant: "success" });
                    setType(type + 1);
                  } else {
                    enqueueSnackbar("Please add sign for all recipient", {
                      variant: "warning",
                    });
                  }
                } else {
                  if (signActions[type] === "Add Recipients") {
                    // enqueueSnackbar("Please Enter the data", {
                    //   variant: "warning",
                    // });
                    if (await handleSave()) {
                      setType(type + 1);
                    }
                  } else {
                    handleSave();
                    setType(type + 1);
                  }
                }
              }}
            >
              <div className="flex items-center justify-between">
                <p className="font-semibold">Next</p>
                <BiRightArrowAlt
                  size={15}
                  className="text-white ml-1 cursor-pointer"
                  title="previous"
                />
              </div>
            </button>
          )}
          {signActions[type] === "Review" && (
            <div
              className="rounded-lg bg-green cursor-pointer px-[10px] py-[3px]"
              onClick={() => {
                // setType(type + 1);
                handleSave();
              }}
            >
              <div className="flex items-center justify-between">
                {/* <p className="font-semibold">Submit</p> */}
                <p className="font-semibold">
                  {currentDocInfo?.documentList[0]?.status !== "completed" &&
                  currentDocInfo?.documentPreferencesModel?.senderStatus ==
                    "completed"
                    ? "Resend"
                    : "Submit"}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div>
        <Divider />
      </div>
    </>
  );
};
