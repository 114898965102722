// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Chip,
  LinearProgress,
  Button,
} from "@mui/material";
import React, { useContext, useEffect, useState, useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { fabric } from "fabric";
import { FaFileSignature, FaSignature, FaUndo } from "react-icons/fa";
import { CgCalendarDates } from "react-icons/cg";
import { RiText } from "react-icons/ri";
import DocumentDetailsContext from "../../context/DocumentsDetails/documentDetails.context";
import { useSnackbar } from "notistack";
import { DocumentNavigator } from "./DocumentNavigator";
import { componentIdKindMap } from "../Recepientsign/RecepientSign";
import { bgcolor } from "@mui/system";
import axios from "axios";
import { useDetectClickOutside } from "react-detect-click-outside";

// import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Swal from "sweetalert2";
import { colors, fonts, fontSizes } from "./const";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const signTypes: any = {
  initial: "733dc378-0f4f-4c6e-8efc-c6fb0f8861a0",
  text: "a29817b6-211b-41b9-b362-d681af47544b",
  sign: "f02cfd06-1d79-467f-a3d9-d9851d961c66",
  date: "bce97a04-4b32-459b-8e42-13f24ec32fd1",
};
// const shadeColor = (color:any, percent: any) => {
//   let R:any = parseInt(color.substring(1,3),16);
//    let G:any = parseInt(color.substring(3,5),16);
//     let B:any = parseInt(color.substring(5,7),16);
//      R = parseInt(String.toString(R * ((100) + percent) / 100));
//      G = parseInt(G * (100 + percent) / 100);
//       B = parseInt(B * (100 + percent) / 100);
//        R = (R<255)?R:255; G = (G<255)?G:255;
//        B = (B<255)?B:255;
//        let RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
//         let GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
//         let BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));
//         return "#"+RR+GG+BB;
//   }

// const adjust = (color: any, amount: any) => {
//    return '#' + color.replace(/^#/, '').replace(/../g, (color:any) => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
//   }

const colorShade = (col: any, amt: any) => {
  console.log("col", col);
  col = col.replace(/^#/, "");
  if (col.length === 3)
    col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2];
  let [r, g, b] = col.match(/.{2}/g);
  [r, g, b] = [
    parseInt(r, 16) + amt,
    parseInt(g, 16) + amt,
    parseInt(b, 16) + amt,
  ];
  r = Math.max(Math.min(255, r), 0).toString(16);
  g = Math.max(Math.min(255, g), 0).toString(16);
  b = Math.max(Math.min(255, b), 0).toString(16);
  const rr = (r.length < 2 ? "0" : "") + r;
  const gg = (g.length < 2 ? "0" : "") + g;
  const bb = (b.length < 2 ? "0" : "") + b;

  return `#${rr}${gg}${bb}`;
};

function getRandomColor() {
  var letters = "BCDEF".split("");
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * letters.length)];
  }
  return color;
}

const addSign = (bgColor: any, left: any = 200, height: any = 200) => {
  console.log("HieghtArea", height);
  console.log("left", left);

  return new Promise((resolve, reject) => {
    fabric.Image.fromURL(
      require("./SI.png"),
      (img: any) => {
        img.set({
          stroke: colorShade(bgColor, -42),
          strokeWidth: 3,
          left: left,
          top: height,
        });

        resolve(img);
      },
      { backgroundColor: bgColor }
    );
  });
};

const addInit = (bgColor: any, left: any = 250, height: any = 250) => {
  return new Promise((resolve, reject) => {
    fabric.Image.fromURL(
      require("./IN.png"),
      (img: any) => {
        img.set({
          stroke: colorShade(bgColor, -42),
          strokeWidth: 3,
          left: left,
          top: height,
        });
        resolve(img);
        console.log("imggggg", img);
      },
      { backgroundColor: bgColor }
    );
  });
};

export const handleCanvasConvertion = (canvasID: string) => {
  let c: any = document.getElementById(canvasID);
  let bg = c.toDataURL("image/png");
  let canvas = new fabric.Canvas(canvasID);

  canvas.setBackgroundImage(bg, canvas.renderAll.bind(canvas));
  // console.log(
  //   "handleCanvasConvertion: canvas.toDatalessJSON: ",
  //   canvas.toDatalessJSON()
  // );
  return canvas;
};

export let fabricCanvas: any[] = [];
let anotationData: any = [];
let documentPreprationData: any[] = [];

export const updateFabric = (fabric: any, zoomfactor: number = 1) => {
  let updatedFabric: any = fabric.map((fab: any, idx: any) => ({
    ...fab,
    componentList: fab.componentList.map((comp: any, idx: any) => ({
      componentId: comp.componentId,
      recipientId: comp.recipientId,
      kind: comp.kind,
      uniqueId: comp?.uniqueId,

      boundingBox:
        comp.boundingBox ||
        Object.values(comp.component.getBoundingRect()).map(
          (data: any) => data / zoomfactor
        ),
      imageBase64:
        comp.componentImg ||
        comp.component.toDataURL({
          //withoutTransform: true,
        }),
    })),
  }));

  return updatedFabric;
};

const BorderIText = fabric.util.createClass(fabric.IText, {
  render: function (ctx: any) {
    this.clearContextTop();
    this.callSuper("render", ctx);
    this.cursorOffsetCache = {};
    this.renderCursorOrSelection();
    ctx.strokeStyle = this.boxColor || this.fill;
    ctx.lineWidth = 3;
    let coords = this.getCoords();
    ctx.beginPath();
    ctx.moveTo(coords[0].x, coords[0].y);
    ctx.lineTo(coords[1].x, coords[1].y);
    ctx.lineTo(coords[2].x, coords[2].y);
    ctx.lineTo(coords[3].x, coords[3].y);
    ctx.closePath();
    ctx.stroke();
  },

  _render: function (ctx: any) {
    this.callSuper("_render", ctx);
  },
});

const BorderText = fabric.util.createClass(fabric.Text, {
  render: function (ctx: any) {
    // this.clearContextTop();
    this.callSuper("render", ctx);
    this.cursorOffsetCache = {};
    // this.renderCursorOrSelection();
    ctx.strokeStyle = this.boxColor || this.fill;
    ctx.lineWidth = 3;
    let coords = this.getCoords();
    ctx.beginPath();
    ctx.moveTo(coords[0].x, coords[0].y);
    ctx.lineTo(coords[1].x, coords[1].y);
    ctx.lineTo(coords[2].x, coords[2].y);
    ctx.lineTo(coords[3].x, coords[3].y);
    ctx.closePath();
    ctx.stroke();
  },

  _render: function (ctx: any) {
    this.callSuper("_render", ctx);
  },
});

const baseURL = process.env.REACT_APP_API_URL;
const headerKey = process.env.REACT_APP_API_HEADER_KEY;
interface PrepareDocumentProps {
  type: number;
  setType: any;
}

export const PrepareDocument: React.FC<PrepareDocumentProps> = ({
  type,
  setType,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    loading: docLoading,
    documentDetails,
    getPlaceholderData,
    setPlaceHolderData,
    savedRecipientList,
    placeholderdata,
    deleteSignComponent,
  } = useContext<any>(DocumentDetailsContext);
  const [numPages, setNumPages] = useState<number>(0);
  const [fabricText, setFabricText] = useState<any>([]);
  const [fabricSign, setFabricSign] = useState<any>([]);
  const [fabricInitials, setFabricInitials] = useState<any>([]);
  const [fabricDate, setFabricDate] = useState<any>([]);
  const [fabricSavedPlaceholder, setFabricSavedPlaceholder] = useState<any>({});
  const [currentPDF, setCurrentPDF] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [currentDocumentIndex, setCurrentDocumentIndex] = useState<number>(0);
  const [activeRecipient, setActiveRecipient] = useState<any>(null);
  const [currentDocument, setCurrentDocument] = useState<any>(
    documentDetails.documentDetails[0]
  );
  const [textSignState, setTextSignState] = useState();
  const [loadedPages, setLoadedPages] = useState<any>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [signLoading, setSignLoading] = useState<boolean>(true);
  const [showApply, setShowApply] = useState<boolean>(false);
  const [pdfZoom, setPdfZoom] = useState<number>(0.8);
  const [fabricSavedComponent, setFabricSavedComponent] = useState<any>([]);
  const [docDataLoaded, setDocDataLoaded] = useState<any>({});
  // const [docUpdated, setDocUpdated] = useState<boolean>(false);
  const [textFont, setTextFont] = useState<any>(null);
  const [textSize, setTextSize] = useState<any>(null);
  const [textColor, setTextColor] = useState<any>(null);
  const [deletedSign, setDeletedSign] = useState<any>([]);
  const [currentZoomLevel, setCurrentZoomLevel] = useState<any>(100);
  const placeholderRef = useRef<any>({});
  const [CurrentActiveDocument, setCurrentActiveDocument] = useState<any>(1);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [randomState, setRandomState] = useState();
  // const handleFullScreen = useFullScreenHandle();

  const totalDocumentsLength = documentDetails.documentDetails.length;
  console.log(
    " documentDetails.documentDetails",
    documentDetails.documentDetails,
    totalDocumentsLength
  );
  // const CurrentActiveDocument = 1;
  const updateCurrentdocumentIndex = (doc: any) => {
    let currentnumber =
      documentDetails.documentDetails.findIndex((item: any, index: number) => {
        return item.documentsOriginalName === doc.documentsOriginalName;
      }) + 1;

    setCurrentActiveDocument(currentnumber);
  };
  // const [enableTheFeature, setEnableTheFeature] = useState<any>({});
  const [highLightEnableFeature, setHighLightEnableFeature] = useState<any>({
    applyShadow: Boolean,
    type: String,
  });

  /*  useEffect(() => {
    window.addEventListener("resize", getSizes, false);

    function getSizes() {
      console.log("inner", window.outerWidth);

      console.log(" window.innerWidth", window.innerWidth);

      let zoom = ((window.outerWidth - 10) / window.innerWidth) * 100;
      console.log("zooo,", zoom);
      var out = Math.ceil(zoom);
      setCurrentZoomLevel(out);
      console.log("out", out);
    }

    getSizes();
  });
 */
  /*   useEffect(() => {
    window.addEventListener("message", getSizes, false);

    function getSizes(evt: any) {
      console.log("inner", window.outerWidth);

      console.log(" window.innerWidth", evt.data);

      let zoom = ((window.outerWidth - 10) / evt.data) * 100;
      console.log("zooo,", zoom);
      var out = Math.ceil(zoom);
      setCurrentZoomLevel(out);
      console.log("out", out);
    }

    //getSizes(evt);
  }); */

  useEffect(() => {
    window.addEventListener("resize", getSizes, false);

    function getSizes() {
      let zoom = ((window.outerWidth - 10) / window.innerWidth) * 100;

      var out = Math.ceil(zoom);
      setCurrentZoomLevel(out);
      console.log("out", out);
    }

    getSizes();
  });

  useEffect(() => {
    setLoading(true);
    fabricCanvas = documentDetails.documentDetails.map((doc: any) => ({
      docId: doc.id,
      fabric: {},
      pageCanvasRefs: {},
    }));
    console.log("fabricCanvas in useEffect resetted");
    setLoading(false);
  }, [currentDocument]);
  // console.log("fabric canvas",fabricCanvas)
  // console.log(fabricText);

  useEffect(() => {
    if (savedRecipientList.length > 0) {
      setActiveRecipient(savedRecipientList[0]);
    }
  }, [savedRecipientList]);

  useEffect(() => {
    console.log("called placeholder data", currentDocument?.id);
    getPlaceholderData(currentDocument?.id);
    // setFabricSign([]);
    // setFabricInitials([]);
    // setFabricText([])
    setShowApply(false);
    setDocDataLoaded({});
    setDeletedSign([]);
  }, [currentDocument.id]);

  useEffect(() => {
    if (numPages === 0) {
      return;
    }

    if (loadedPages < numPages) {
      return;
    }

    let pdfCanvas = document.querySelectorAll(".react-pdf__Page__canvas");
    pdfCanvas.forEach((_: any, pageNumber: any) => {
      // console.log("processing page", pageNumber);
    });
  }, [currentDocument, loadedPages, numPages]);

  useEffect(() => {
    if (!placeholderdata || Object.keys(placeholderdata).length < 1) return;
    // console.log("placeholder data", placeholderdata);
    placeholderRef.current = placeholderdata;
    savedPlaceholder();
    // setTimeout(() => {
    //   showComponents();
    // }, 100);

    console.log(
      "useeffect show com",
      docDataLoaded[placeholderdata?.documentDetailsId]
    );
    if (!docDataLoaded[placeholderdata?.documentDetailsId]) showComponents();
    setLoading(false);
  }, [placeholderdata]);

  const [zoomState, setZoomState] = useState(1);
  useEffect(() => {
    let zoomfactor = currentZoomLevel / 100;
    let systemZoom = window.devicePixelRatio;
    let normalizedZoom = zoomfactor / systemZoom;
    setZoomState(normalizedZoom);
  }, []);
  const savedPlaceholder = () => {
    let newData: any = [];
    if (placeholderdata?.documentDetailsId === currentDocument.id) {
      placeholderdata?.documentPages?.map((pageInfo: any) => {
        if (pageInfo.pageNumber === 1) {
          pageInfo?.componentList?.map((data: any) => {
            // console.log({ data });
            let info = {
              componentId: data.componentId,
              recipientId: data.recipientId,
              component: data.imageBase64,
              kind: componentIdKindMap[data.componentId],
              // kind: data.kind,
              boundingBox: data.boundingBox,
            };
            newData.push(info);
          });
        }
      });
      setFabricSavedComponent(newData);
    }
  };
  const showComponents = (data: any = placeholderdata) => {
    let zoomfactor = currentZoomLevel / 100;
    // console.log({ fabricCanvas });
    // placeholderdata?.documentPages?.map((pageInfo: any) => {
    //   pageInfo?.componentList?.map((data: any) => {
    //     console.log({ data });
    //     fabric.Image.fromURL(data.imageBase64, (img: any) => {
    //       img.set({ left: data.boundingBox[0], top: data.boundingBox[1] });
    //       fabricCanvas[currentDocumentIndex].fabric[
    //         pageInfo.pageNumber - 1
    //       ].add(img);
    //     });
    //   });
    // });
    // let documentPages = placeholderdata.documentPages;
    // console.log({ placeholderdata });
    // let fabricPlaceholder: any = {
    //   text: [],
    //   initial: [],
    //   sign: [],
    //   date: [],
    // };
    setSignLoading(false);
    // console.log(
    //   "show comp called ",
    //   placeholderdata?.documentDetailsId === currentDocument.id,
    //   placeholderdata,
    //   currentDocument
    // );
    if (data?.documentDetailsId === currentDocument.id) {
      // console.log("show comp called ");
      setDocDataLoaded({ [data?.documentDetailsId]: true });

      let documentPages = data.documentPages;
      documentPages.map((pageInfo: any) => {
        let canvas =
          fabricCanvas[currentDocumentIndex]?.fabric[pageInfo.pageNumber - 1];
        // canvas?.on("object:moving", handleObjectMovement);
        //  console.log("canvas", canvas);
        canvas?.remove(...canvas?.getObjects());
        pageInfo.componentList.map((data: any) => {
          // let kind = componentIdKindMap[data.componentId];
          // fabricPlaceholder[kind].push(data);
          // console.log(
          //   fabricPlaceholder[kind],
          //   componentIdKindMap[data.componentId]
          // );
          fabric.Image.fromURL(data.imageBase64, (img: any) => {
            img.set({
              left: data.boundingBox[0] * zoomfactor,
              top: data.boundingBox[1] * zoomfactor,
              id: data.id,
              componentId: data.componentId,
              recipientId: data.recipientId,
            });
            fabricCanvas[currentDocumentIndex].fabric[
              pageInfo.pageNumber - 1
            ]?.add(img);
          });
        });
      });
    }

    // console.log("fabric PlaceHolder", fabricPlaceholder);
    // setFabricSavedPlaceholder(fabricPlaceholder);

    // components.forEach((component: any) => {
    //   fabric.Image.fromURL(component.imageBase64, (img: any) => {
    //     img.set({
    //       left: component.boundingBox[0],
    //       top: component.boundingBox[1],
    //     });
    //     fabricCanvas[currentDocumentIndex].fabric[component.pageNumber - 1].add(
    //       img
    //     );
    //   });
    // });
  };
  const handleObjectMovement = (e: any) => {
    var obj = e.target;

    // console.log("Object",obj)
    obj.setCoords();

    if (obj.getBoundingRect().top < 0 || obj.getBoundingRect().left < 0) {
      obj.top = Math.max(obj.top, 0);
      obj.left = Math.max(obj.left, 0);
    }

    if (
      obj.getBoundingRect().top + obj.getBoundingRect().height >
        obj.canvas.height ||
      obj.getBoundingRect().left + obj.getBoundingRect().width >
        obj.canvas.width
    ) {
      obj.top = Math.min(
        obj.top,
        obj.canvas.height - obj.getBoundingRect().height
      );
      obj.left = Math.min(
        obj.left,
        obj.canvas.width - obj.getBoundingRect().width
      );
    }
  };
  const onDocumentLoadSuccess = async (pdf: any) => {
    console.log("OnDocumentLoadSuccess called with pdf: ", pdf);
    setCurrentPDF(pdf);
    setNumPages(pdf.numPages);
    // setNumPages(2);
    // setSignLoading(true);
    // setTimeout(() => {
    //   showComponents();
    // }, 4000);
  };

  const onDocumentLoadError = (error: any) => {
    console.log({ error });
  };

  const onPageLoadSuccess = async (page: any) => {
    // console.log("onPageLoadSuccess: page - ", page);
  };

  const onPageRenderSuccess = async (pageNumber: any) => {
    setLoadedPages(loadedPages + 1);
    console.log("onPageRenderSuccess: processing page ", pageNumber);
    // debugger;

    // fabricCanvas[currentDocumentIndex].fabric[pageNumber] = handleCanvasConvertion(
    //   `canvas-${pageNumber}`
    // );

    fabricCanvas[currentDocumentIndex].fabric[pageNumber] =
      addFabricCanvasToPage(pageNumber);
    let canvas = fabricCanvas[currentDocumentIndex].fabric[pageNumber];
    canvas?.on("object:moving", handleObjectMovement);
    if (pageNumber === numPages - 1) {
      setSignLoading(true);
      setTimeout(() => {
        // console.log("render end show com");
        showComponents(placeholderRef.current);
      }, 3000);
    }
  };

  const addFabricCanvasToPage = (pageNumber: number) => {
    // let c: any = document.getElementById(canvasID);
    // let bg = c.toDataURL("image/png");
    const pageCanvasRef =
      fabricCanvas[currentDocumentIndex].pageCanvasRefs[pageNumber];
    // debugger;
    const bg = pageCanvasRef.toDataURL("image/png");
    let canvas = new fabric.Canvas(pageCanvasRef, {
      // fill: null,
    });
    canvas.setBackgroundImage(bg, canvas.renderAll.bind(canvas));
    // console.log(
    //   "handleCanvasConvertion: canvas.toDatalessJSON: ",
    //   canvas.toDatalessJSON()
    // );

    return canvas;
  };
  // console.log("Active recipient", activeRecipient);
  const handleZoom = () => {
    //  setPdfZoom((prev) => prev + 0.1);
  };
  const handleText = (Width: any, Height: any, pageIndex: any = 0) => {
    // let canvas = null;
    let index = currentDocumentIndex; /*fabricCanvas.findIndex(
      (fabric: any) => fabric.docId === currentDocument.id
    );*/

    // console.log({ fabricCanvas, index });
    /*  if (checkForDuplicate("text", fabricText, pageIndex)) {
      enqueueSnackbar("already present", { variant: "error" });
      return;
    } */
    let color = activeRecipient.colorCode;

    //

    // let inputText: any = new fabric.IText("Add text", {
    //   left: 100,
    //   top: 100,
    //   fill:color,
    // });
    let options: any = {
      left: Width,
      top: Height,
      boxColor: color,
    };
    if (textFont?.name) {
      options.fontFamily = textFont.name;
    }
    if (textSize) {
      options.fontSize = textSize;
    }
    if (textColor) {
      options.fill = textColor;
    }

    let inputText: any = new BorderIText("Add text", options);
    // console.log({ inputText });
    let compId = documentDetails.documentDetailsSignComponent.filter(
      (type: any) => type.componentName === "text"
    );

    let randonArray = [];
    let CurrentDate = new Date();
    let filterDate = CurrentDate.getSeconds();

    let pageData = {
      pageNumber: pageIndex,
      componentList: [
        {
          uniqueId: `${Math.ceil(
            Math.random() * 10000
          )}-${pageIndex}-${filterDate}`,
          componentId: `canvas-${pageIndex}-${compId[0].id}`,
          recipientId: activeRecipient?.id,
          component: inputText,
          kind: "text",
        },
      ],
    };
    let newText = [...fabricText, pageData];
    setFabricText(newText);
    inputText["componentId"] = pageData.componentList[0].componentId;
    inputText["kind"] = pageData.componentList[0].kind;
    inputText["recipientId"] = pageData.componentList[0].recipientId;
    inputText["uniqueId"] = pageData.componentList[0].uniqueId;
    fabricCanvas[index].fabric[pageIndex].add(inputText);
  };
  const [nameColorMap, setnameColorMap] = useState(new Map());

  const makeSignRect = async (Width: any, Height: any, pageIndex: any = 0) => {
    if (!activeRecipient) {
      enqueueSnackbar("Please select a recipient", { variant: "error" });
      return;
    }
    /*   console.log("fabricSign", fabricSign);
    console.log("pageIndex", pageIndex);
    console.log("currentDocumentIndex", currentDocumentIndex); */

    /*    if (checkForDuplicate("sign", fabricSign, pageIndex)) {
      enqueueSnackbar("already present", { variant: "error" });
      return;
    } */
    let index = currentDocumentIndex; /*fabricCanvas.findIndex(
      (fabric: any) => fabric.docId === currentDocument.id
    );*/
    // console.log({ fabricCanvas, index, currentDocument });
    // let canvas = fabricCanvas[currentPage];
    let bgColor = activeRecipient.colorCode;

    let text = `Add Sign ${activeRecipient.name}`;
    // let textSign: any = new fabric.Text(text, {
    //   fill: "#000",
    //   backgroundColor: bgColor,
    //   padding: 4,
    //   fontSize: 20,
    //   lockMovementX: false,
    //   lockMovementY: false,
    //   hasControls: true,
    // });
    let bgWidth = fabricCanvas[index].fabric[pageIndex]?.backgroundImage?.width;

    if (!isNaN(parseInt(bgWidth))) {
      bgWidth = Width;
    }

    let textSign: any = await addSign(bgColor, bgWidth, Height);
    fabricCanvas[index].fabric[pageIndex].add(textSign);

    let compId = documentDetails.documentDetailsSignComponent.filter(
      (type: any) => type.componentName === "sign"
    );

    let CurrentDate = new Date();
    let filterDate = CurrentDate.getSeconds();
    let pageData = {
      pageNumber: pageIndex,
      componentList: [
        {
          uniqueId: `${Math.ceil(
            Math.random() * 10000
          )}-${pageIndex}-${filterDate}`,
          componentId: `canvas-${index}-${compId[0].id}`,
          recipientId: activeRecipient?.id,
          component: textSign,
          kind: "sign",
        },
      ],
    };
    console.log("pageData pageData sign", pageData);
    let newTextSign = [...fabricSign, pageData];
    setFabricSign(newTextSign);
    textSign["componentId"] = pageData.componentList[0].componentId;
    textSign["kind"] = pageData.componentList[0].kind;
    textSign["recipientId"] = pageData.componentList[0].recipientId;
    textSign["uniqueId"] = pageData.componentList[0].uniqueId;
  };

  var CoordinatesData = document.querySelector(
    "#CoordinatesData"
  ) as HTMLElement | null;

  var InnerArea = document.querySelector("#innerArea");

  var widthArea: any;
  var HieghtArea: any;

  function showCoords(event: any) {
    var axisX = event.clientX;
    var axisY = event.clientY;
    var x = event.nativeEvent.offsetX;
    var y = event.nativeEvent.offsetY;
    widthArea = x;
    HieghtArea = y;
    console.log("showCoords: axisX", axisX + "axisY", axisY);
    console.log("showCoords: x", x + "y", y);
  }

  const AddselectedFeatureOnClickfunction = (index: any, fieldname: any) => {
    // setCurrentPage(index);

    switch (fieldname) {
      case "signature":
        makeSignRect(widthArea, HieghtArea, index);
        break;
      case "initials":
        makeInitalsCircle(widthArea, HieghtArea, index);

        break;

      case "datesigned":
        handleDate(widthArea, HieghtArea, index);

        break;

      case "text":
        handleText(widthArea, HieghtArea, index);

        break;
      default:
        break;
    }
  };

  const handleSelectItem = (fieldname: string) => {
    if (highLightEnableFeature.type == fieldname) {
      setHighLightEnableFeature({
        ...highLightEnableFeature,
        applyShadow: false,
        type: "none",
      });
      return;
    }
    switch (fieldname) {
      case "signature":
        // setHighLightEnableFeature(current => !current);
        setHighLightEnableFeature({
          ...highLightEnableFeature,
          applyShadow: true,
          type: "signature",
        });
        break;

      case "initials":
        setHighLightEnableFeature({
          ...highLightEnableFeature,
          applyShadow: true,
          type: "initials",
        });
        break;

      case "datesigned":
        setHighLightEnableFeature({
          ...highLightEnableFeature,
          applyShadow: true,
          type: "datesigned",
        });
        break;

      case "text":
        setHighLightEnableFeature({
          ...highLightEnableFeature,
          applyShadow: true,
          type: "text",
        });
        break;

      case "reset":
        Swal.fire({
          title: "Are you sure you want to Reset All?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            deleteAll();
          }
        });
        setHighLightEnableFeature({
          ...highLightEnableFeature,
          applyShadow: true,
          type: "reset",
        });
        break;

      default:
        break;
    }
  };

  function clearCoor() {
    // console.log("helloe");
    // document.getElementById("demo").innerHTML = "";
  }

  const makeInitalsCircle = async (
    width: any,
    height: any,
    pageIndex: any = 0
  ) => {
    if (!activeRecipient) {
      enqueueSnackbar("Please select a recipient", { variant: "error" });
      return;
    }
    /*    if (checkForDuplicate("initial", fabricInitials, pageIndex)) {
      enqueueSnackbar("already present", { variant: "error" });
      return;
    } */
    let bgColor = activeRecipient.colorCode;

    // if (nameColorMap.has(activeRecipient.name)) {
    //   bgColor = nameColorMap.get(activeRecipient.name);
    // } else {
    //   bgColor =
    //     "#" + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6);
    //   bgColor = String(bgColor);
    //   let name = String(activeRecipient.name);
    //   setnameColorMap(nameColorMap.set(name, bgColor));
    // }
    let index = currentDocumentIndex;
    let text = `Add Initials ${activeRecipient.name}`;
    // let textInitials: any = new fabric.Text(text, {
    //   fill: "#000",
    //   backgroundColor: bgColor,
    //   padding: 4,
    //   fontSize: 20,
    //   lockMovementX: false,
    //   lockMovementY: false,
    //   hasControls: true,
    // });
    let bgWidth = fabricCanvas[index].fabric[pageIndex]?.backgroundImage?.width;
    if (!isNaN(parseInt(bgWidth))) {
      bgWidth = width;
    }
    let textInitials: any = await addInit(bgColor, bgWidth, height);
    let compId = documentDetails.documentDetailsSignComponent.filter(
      (type: any) => type.componentName === "initial"
    );
    let CurrentDate = new Date();
    let filterDate = CurrentDate.getSeconds();
    let pageData = {
      pageNumber: pageIndex,
      componentList: [
        {
          uniqueId: `${Math.ceil(
            Math.random() * 10000
          )}-${pageIndex}-${filterDate}`,
          componentId: `canvas-${index}-${compId[0].id}`,
          recipientId: activeRecipient?.id,
          component: textInitials,
          kind: "initial",
        },
      ],
    };
    console.log("pageData pageData", pageData);
    let newTextInitials = [...fabricInitials, pageData];
    textInitials["componentId"] = pageData.componentList[0].componentId;
    textInitials["kind"] = pageData.componentList[0].kind;
    textInitials["recipientId"] = pageData.componentList[0].recipientId;
    textInitials["uniqueId"] = pageData.componentList[0].uniqueId;
    setFabricInitials(newTextInitials);
    fabricCanvas[index].fabric[pageIndex].add(textInitials);
  };

  const handleChangeRecipient = async (event: SelectChangeEvent) => {
    let value: any = event.target.value;
    setActiveRecipient(event.target.value as string);
    let canvas = fabricCanvas[currentDocumentIndex].fabric[currentPage];
    let selectedObject = canvas.getActiveObject();

    if (selectedObject) {
      // console.log(
      //   "selected object",
      //   selectedObject,
      //   "Page",
      //   selectedObject.componentId?.split("-")[1]
      // );
      if (selectedObject.recipientId !== value.id) {
        let page = parseInt(selectedObject.componentId?.split("-")[1]);
        if (await changeSignRecipient(selectedObject, page, value)) {
          // removeFromState(
          //   selectedObject.kind,
          //   selectedObject.componentId,
          //   selectedObject.recipientId
          // );
          canvas.remove(selectedObject);
        }
      }
    }
    event.stopPropagation();
  };
  const changeSignRecipient = (
    selectedObject: any,
    pageNumber: number,
    recipient: any
  ) => {
    return editSignature(
      recipient.colorCode,
      selectedObject,
      pageNumber,
      recipient,
      selectedObject.kind
    );
  };
  const editSignature = async (
    color: string,
    selectedObject: any,
    pageIndex: number,
    recipient: any,
    type: string
  ) => {
    //  console.log("===>>>", type, recipient.id);

    let component: any;
    switch (selectedObject.kind) {
      case "sign":
        if (checkForDuplicate(type, fabricSign, pageIndex, recipient?.id)) {
          enqueueSnackbar("already present", { variant: "error" });
          return false;
        }
        component = await addSign(
          color,
          selectedObject.left,
          selectedObject.top
        );
        break;
      case "initial":
        if (checkForDuplicate(type, fabricInitials, pageIndex, recipient?.id)) {
          enqueueSnackbar("already present", { variant: "error" });
          return false;
        }
        component = await addInit(
          color,
          selectedObject.left,
          selectedObject.top
        );
        break;

      default:
        return false;
    }
    component["componentId"] = selectedObject.componentId;
    component["kind"] = selectedObject.kind;
    component["recipientId"] = recipient.id;

    fabricCanvas[currentDocumentIndex].fabric[pageIndex].add(component);
    editSignFromState(
      selectedObject.kind,
      selectedObject.componentId,
      selectedObject.recipientId,
      recipient.id,
      component,
      selectedObject.uniqueId
    );
    return true;
  };

  const handleChangeFont = (event: SelectChangeEvent) => {
    setTextFont(event.target.value as string);

    let canvas = fabricCanvas[currentDocumentIndex].fabric[currentPage];
    let selectedObject = canvas?.getActiveObject();

    let font: any = event.target.value;
    if (selectedObject) {
      selectedObject.set("fontFamily", font.name);

      canvas.requestRenderAll();
    } else {
      enqueueSnackbar("Please select a text", { variant: "warning" });
    }

    event.stopPropagation();
  };
  const handleChangeSize = (event: SelectChangeEvent) => {
    let size = parseInt(event.target.value, 10);
    setTextSize(size);
    let canvas = fabricCanvas[currentDocumentIndex].fabric[currentPage];
    let selectedObject = canvas?.getActiveObject();

    if (selectedObject) {
      selectedObject.set("fontSize", size);
      canvas.requestRenderAll();
    } else {
      enqueueSnackbar("Please select a text", { variant: "warning" });
    }
    event.stopPropagation();
  };
  const handleChangeColor = (event: SelectChangeEvent) => {
    setTextColor(event.target.value as string);
    let canvas = fabricCanvas[currentDocumentIndex].fabric[currentPage];
    let selectedObject = canvas?.getActiveObject();

    if (selectedObject) {
      selectedObject.set("fill", event.target.value);
      canvas.requestRenderAll();
    } else {
      enqueueSnackbar("Please select a text", { variant: "warning" });
    }
    event.stopPropagation();
  };
  const handleDate = (Width: any, Height: any, pageIndex: any = 0) => {
    if (!activeRecipient) {
      enqueueSnackbar("Please select a recipient", { variant: "error" });
      return;
    }
    // let canvas = null;
    let index = currentDocumentIndex;
    // fabricDate.forEach((compData: any) => {
    //   if (compData.pageNumber == currentPage) {
    //     compData.componentList.forEach((comp: any) => {
    //       if (comp.kind == "date" && comp.recipientId == activeRecipient.id) {
    //         enqueueSnackbar("already present", { variant: "error" });
    //         return;
    //       }
    //     });
    //   }
    // });

    // for (let [i, compData] of fabricDate.entries()) {
    //   if (compData.pageNumber == currentPage) {
    //     for (let [j, comp] of compData.componentList.entries()) {
    //       if (comp.kind == "date" && comp.recipientId == activeRecipient.id) {
    //         enqueueSnackbar("already present", { variant: "error" });
    //         return;
    //       }
    //     }
    //   }
    // }

    /* if (checkForDuplicate("date", fabricDate, pageIndex)) {
      enqueueSnackbar("already present", { variant: "error" });
      return;
    } */
    let color = activeRecipient.colorCode;
    let date: any = new BorderText("Sign Date", {
      fontSize: 20,
      left: Width,
      top: Height,
      boxColor: color,
    });
    // console.log({ inputText });
    let compId = documentDetails.documentDetailsSignComponent.filter(
      (type: any) => type.componentName === "date"
    );
    let CurrentDate = new Date();
    let filterDate = CurrentDate.getSeconds();
    console.log(
      "filtyer",
      `${Math.ceil(Math.random() * 10000)}-${pageIndex}-${filterDate}`
    );
    let pageData = {
      pageNumber: pageIndex,
      componentList: [
        {
          uniqueId: `${Math.ceil(
            Math.random() * 10000
          )}-${pageIndex}-${filterDate}`,
          componentId: `canvas-${pageIndex}-${compId[0].id}`,
          recipientId: activeRecipient?.id,
          component: date,
          kind: "date",
        },
      ],
    };
    let newDate = [...fabricDate, pageData];
    setFabricDate(newDate);
    date["componentId"] = pageData.componentList[0].componentId;
    date["kind"] = pageData.componentList[0].kind;
    date["recipientId"] = pageData.componentList[0].recipientId;
    date["uniqueId"] = pageData.componentList[0].uniqueId;
    fabricCanvas[index].fabric[pageIndex].add(date);
  };

  console.log("fabricSign", fabricSign);
  const deleteSelectedCanvasObject = async () => {
    let canvas = fabricCanvas[currentDocumentIndex].fabric[currentPage];
    console.log("nayan", canvas);
    let selectedObject = canvas.getActiveObject();
    console.log("currentPage ", currentPage);
    console.log("deletedSign ", deletedSign);
    console.log("deletedSign currentDocument", currentDocument);
    console.log("deletedSign selectedObject", selectedObject);
    console.log("deletedSign selectedObject?.id", selectedObject?.id);
    if (selectedObject) {
      canvas.remove(selectedObject);
      setDocDataLoaded({ [currentDocument.id]: false });
      if (selectedObject?.id) {
        let res = await deleteSignComponent(
          currentDocument.id,
          selectedObject.id
        );
        if (res.status == 200) {
          // console.log("selectedObject.uniqueId 1", selectedObject.uniqueId)
          // console.log("currentPage 1", currentPage)
          setDeletedSign([
            ...deletedSign,
            {
              componentId: selectedObject.componentId,
              pageNumber: currentPage,
              recipientId: selectedObject.recipientId,
              uniqueId: selectedObject.uniqueId,
            },
          ]);
          // console.log("selectedObject.uniqueId 2", selectedObject.uniqueId)
          // console.log("currentPage 2", currentPage)
        } else {
          enqueueSnackbar("failed to delete", { variant: "error" });
          canvas.add(selectedObject);
        }
        console.log("deletedSign 2", deletedSign);
        console.log("fabricSign after", fabricSign);
      }
      removeFromState(
        selectedObject.kind,
        selectedObject.componentId,
        selectedObject.recipientId,
        selectedObject.uniqueId
      );
    } else {
      enqueueSnackbar("Please select a object", { variant: "error" });
    }
  };

  const removeFromState = (
    kind: string,
    Id: string,
    recipentId: string,
    uniqueId: string
  ) => {
    console.log(
      "kind Id recipentId uniqueId =========",
      kind,
      Id,
      recipentId,
      uniqueId
    );
    switch (kind) {
      case "date":
        let newDate = fabricDate;
        newDate = newDate.filter((comp: any) => {
          return !(
            comp.componentList[0].componentId == Id &&
            comp.componentList[0].uniqueId == uniqueId &&
            comp.componentList[0].recipientId == recipentId
          );
        });
        setFabricDate(newDate);

        break;
      case "sign":
        let newSign = fabricSign;
        console.log("newSign1", newSign);
        newSign = newSign.filter((comp: any) => {
          return !(
            comp.componentList[0].componentId == Id &&
            comp.componentList[0].uniqueId == uniqueId &&
            comp.componentList[0].recipientId == recipentId
          );
        });
        setFabricSign(newSign);
        console.log("newSign2", newSign);
        break;

      case "initial":
        let newInitials = fabricInitials;
        newInitials = newInitials.filter((comp: any) => {
          return !(
            comp.componentList[0].componentId == Id &&
            comp.componentList[0].uniqueId == uniqueId &&
            comp.componentList[0].recipientId == recipentId
          );
        });
        setFabricInitials(newInitials);
        break;

      case "text":
        let newText = fabricText;
        newText = newText.filter((comp: any) => {
          return !(
            comp.componentList[0].componentId == Id &&
            comp.componentList[0].uniqueId == uniqueId &&
            comp.componentList[0].recipientId == recipentId
          );
        });
        setFabricText(newText);
        break;

      default:
        break;
    }
  };

  const editSignFromState = (
    kind: string,
    Id: string,
    recipentId: string,
    newReipientId: string,
    component: any,
    uniqueId: string
  ) => {
    // console.log("EDIT", recipentId, newReipientId);
    switch (kind) {
      case "date":
        let newDate = fabricDate;
        newDate = newDate.map((comp: any) => {
          if (
            comp.componentList[0].componentId == Id &&
            comp.componentList[0].uniqueId == uniqueId &&
            comp.componentList[0].recipientId == recipentId
          ) {
            comp.componentList[0].recipientId = newReipientId;
            comp.componentList[0].component = component;
          }
          return comp;
        });
        setFabricDate(newDate);
        break;
      case "sign":
        let newSign = fabricSign;
        newSign = newSign.map((comp: any) => {
          if (
            comp.componentList[0].componentId == Id &&
            comp.componentList[0].uniqueId == uniqueId &&
            comp.componentList[0].recipientId == recipentId
          ) {
            comp.componentList[0].recipientId = newReipientId;
            comp.componentList[0].component = component;
          }
          return comp;
        });
        setFabricSign(newSign);
        break;

      case "initial":
        let newInitials = fabricInitials;
        newInitials = newInitials.map((comp: any) => {
          if (
            comp.componentList[0].componentId == Id &&
            comp.componentList[0].uniqueId == uniqueId &&
            comp.componentList[0].recipientId == recipentId
          ) {
            comp.componentList[0].recipientId = newReipientId;
            comp.componentList[0].component = component;
          }
          return comp;
        });
        setFabricInitials(newInitials);
        break;

      case "text":
        let newText = fabricText;
        newText = newText.map((comp: any) => {
          if (
            comp.componentList[0].componentId == Id &&
            comp.componentList[0].uniqueId == uniqueId &&
            comp.componentList[0].recipientId == recipentId
          ) {
            comp.componentList[0].recipientId = newReipientId;
            comp.componentList[0].component = component;
          }
          return comp;
        });
        setFabricText(newText);
        break;

      default:
        break;
    }
  };
  const handleSave = async (isDocumentSwitch: boolean = false) => {
    let singleDocData: any = {
      documentDetailsId: currentDocument?.id,
      documentPages: [],
    };

    for (let i = 0; i < numPages; i++) {
      singleDocData.documentPages.push({
        pageNumber: i + 1,
        componentList: [],
      });
    }

    let zoomfactor = currentZoomLevel / 100;

    let updatedInitials = updateFabric(fabricInitials, zoomfactor);

    let updatedSign = updateFabric(fabricSign, zoomfactor);

    let updatedText = updateFabric(fabricText, zoomfactor);

    let updatedDate = updateFabric(fabricDate, zoomfactor);

    console.log({ updatedInitials, updatedSign, updatedText });

    for (let i = 0; i < numPages; i++) {
      updatedText.forEach((data: any, idx: number) => {
        if (data.pageNumber === i) {
          singleDocData.documentPages[i].componentList.push(
            ...data.componentList
          );
        }
      });
      updatedInitials.forEach((data: any, idx: number) => {
        if (data.pageNumber === i) {
          singleDocData.documentPages[i].componentList.push(
            ...data.componentList
          );
        }
      });

      updatedSign.forEach((data: any, idx: number) => {
        if (data.pageNumber === i) {
          singleDocData.documentPages[i].componentList.push(
            ...data.componentList
          );
        }
      });

      updatedDate.forEach((data: any, idx: number) => {
        if (data.pageNumber === i) {
          singleDocData.documentPages[i].componentList.push(
            ...data.componentList
          );
        }
      });
    }

    console.log("=========================");

    if (
      anotationData.filter((d: any) => d.id === currentDocument.id).length > 0
    ) {
      let index = anotationData.findIndex(
        (d: any) => d.id === currentDocument.id
      );
      let temp1: any[] = [];
      fabricCanvas.forEach((d: any) => {
        if (d.docId === currentDocument.id) {
          Object.keys(d.fabric).forEach((key: any) => {
            temp1.push(d.fabric[key]);
          });
        }
      });
      anotationData[index].jsonData = temp1;
    } else {
      let temp1: any[] = [];
      fabricCanvas.forEach((d: any) => {
        if (d.docId === currentDocument.id) {
          Object.keys(d.fabric).forEach((key: any) => {
            temp1.push(d.fabric[key]);
          });
        }
      });
      anotationData.push({
        id: currentDocument.id,
        jsonData: temp1,
      });
    }

    if (
      documentPreprationData.filter((d) => d.documentId === currentDocument.id)
        .length > 0
    ) {
      let index = documentPreprationData.findIndex(
        (d) => d.documentId === currentDocument.id
      );
      documentPreprationData[index] = singleDocData;
    } else {
      documentPreprationData.push(singleDocData);
    }

    /* console.log(
      "preparationdata",
      documentPreprationData,
      currentDocument.id,
      currentDocumentIndex
    ); */
    let documentPages =
      documentPreprationData[currentDocumentIndex].documentPages;

    let filteredPages = documentPages.filter(
      (doc: any) => doc.componentList.length > 0
    );

    console.log(
      documentPages,
      filteredPages,
      documentPreprationData[currentDocumentIndex]
    );
    documentPreprationData[currentDocumentIndex].documentPages = filteredPages;

    // await setPlaceHolderData(documentPreprationData[currentDocumentIndex]);

    //
    let filteredPagesnew = singleDocData?.documentPages.filter(
      (doc: any) => doc.componentList.length > 0
    );
    singleDocData.documentPages = filteredPagesnew;
    await setPlaceHolderData(singleDocData, isDocumentSwitch);
    // setDocUpdated(!isDocumentSwitch);
    //console.log("single doc data", singleDocData);
    setFabricInitials([]);
    setFabricSign([]);
    setFabricText([]);
    setFabricDate([]);
    setFabricSavedComponent([]);
    setCurrentPage(0);
    setDeletedSign([]);
  };

  const checkForDuplicate = (
    kind: string,
    componentArray: any,
    pageNum: number = 0,
    recipientId?: string,
    pageIndex: number = 0
  ) => {
    let currentPageNumber = pageNum > 0 ? pageNum : pageIndex;

    for (let [i, compData] of componentArray.entries()) {
      if (compData.pageNumber == currentPageNumber) {
        for (let [j, comp] of compData.componentList.entries()) {
          if (
            comp.kind == kind &&
            comp.recipientId == (recipientId || activeRecipient.id)
          ) {
            return true;
          }
        }
      }
    }

    //let savedArray = fabricSavedPlaceholder[kind];
    if (placeholderdata?.documentDetailsId === currentDocument.id) {
      let documentPages = placeholderdata.documentPages;
      // console.log("document Pages", documentPages);
      for (let [i, pageData] of documentPages.entries()) {
        if (pageData.pageNumber == currentPageNumber + 1) {
          for (let [j, comp] of pageData.componentList.entries()) {
            // console.log(
            //   "inside savedarray",
            //   comp,
            //   signTypes[kind],
            //   activeRecipient?.id
            // );
            let isPresent = deletedSign.findIndex((sign: any) => {
              return (
                sign.componentId == signTypes[kind] &&
                sign.pageNumber == currentPageNumber &&
                sign.recipientId == (recipientId || activeRecipient.id)
              );
            });
            console.log(
              "===>",
              isPresent,
              deletedSign,
              signTypes[kind],
              currentPageNumber,
              activeRecipient.id
            );
            if (
              comp.componentId == signTypes[kind] &&
              comp.recipientId == (recipientId || activeRecipient.id) &&
              isPresent < 0
            ) {
              // enqueueSnackbar("already present", { variant: "error" });
              return true;
            }
          }
        }
      }
    }
    return false;
  };
  const checkForDuplicateComponent = (
    kind: string,
    componentArray: any,
    component: any,
    pageNum: number = 0,
    recipientId?: string,
    pageIndex: number = 0
  ) => {
    let currentPageNumber = pageNum > 0 ? pageNum : pageIndex;

    for (let [i, compData] of componentArray.entries()) {
      if (compData.pageNumber == currentPageNumber) {
        for (let [j, comp] of compData.componentList.entries()) {
          // console.log("comp",comp,"component",component);
          if (
            comp.component.kind == kind &&
            comp.component.left == component.left &&
            comp.component.height == component.height &&
            comp.component.top == component.top &&
            comp.component.width == component.width
          ) {
            return true;
          }
        }
      }
    }

    //let savedArray = fabricSavedPlaceholder[kind];
    if (placeholderdata?.documentDetailsId === currentDocument.id) {
      let documentPages = placeholderdata.documentPages;
      // console.log("document Pages", documentPages);
      for (let [i, pageData] of documentPages.entries()) {
        if (pageData.pageNumber == currentPageNumber + 1) {
          for (let [j, comp] of pageData.componentList.entries()) {
            // console.log(
            //   "inside savedarray",
            //   comp,
            //   signTypes[kind],
            //   activeRecipient?.id
            // );
            let isPresent = deletedSign.findIndex((sign: any) => {
              return (
                sign.componentId == signTypes[kind] &&
                sign.pageNumber == currentPageNumber &&
                sign.recipientId == (recipientId || activeRecipient.id)
              );
            });
            // console.log(
            //   "===>",
            //   isPresent,
            //   deletedSign,
            //   signTypes[kind],
            //   currentPageNumber,
            //   activeRecipient.id
            // );
            if (
              comp.componentId == signTypes[kind] &&
              comp.kind == kind &&
              comp.left == component.left &&
              comp.height == component.height &&
              comp.top == component.top &&
              comp.width == component.width &&
              isPresent < 0
            ) {
              // enqueueSnackbar("already present", { variant: "error" });
              return true;
            }
          }
        }
      }
    }
    return false;
  };
  const checkForSavedDuplicateComponent = (
    kind: string,
    componentArray: any,
    component: any,
    pageNum: number = 0,
    recipientId?: string,
    pageIndex: number = 0
  ) => {
    let currentPageNumber = pageNum > 0 ? pageNum : pageIndex;

    for (let [i, compData] of componentArray.entries()) {
      if (compData.pageNumber == currentPageNumber) {
        for (let [j, comp] of compData.componentList.entries()) {
          //  console.log("cfsdc  comp",comp,kind,"component",component);
          if (
            comp.kind == kind &&
            comp.boundingBox[0] == component.boundingBox[0] &&
            comp.boundingBox[1] == component.boundingBox[1] &&
            comp.boundingBox[2] == component.boundingBox[2] &&
            comp.boundingBox[3] == component.boundingBox[3]
          ) {
            return true;
          }
        }
      }
    }
    // console.log("cfsdc=======2nd")
    //let savedArray = fabricSavedPlaceholder[kind];
    if (placeholderdata?.documentDetailsId === currentDocument.id) {
      let documentPages = placeholderdata.documentPages;
      // console.log("document Pages", documentPages);
      for (let [i, pageData] of documentPages.entries()) {
        if (pageData.pageNumber == currentPageNumber + 1) {
          for (let [j, comp] of pageData.componentList.entries()) {
            // console.log(
            //   "inside savedarray",
            //   comp,
            //   signTypes[kind],
            //   activeRecipient?.id
            // );
            let isPresent = deletedSign.findIndex((sign: any) => {
              return (
                sign.componentId == signTypes[kind] &&
                sign.pageNumber == currentPageNumber &&
                sign.recipientId == (recipientId || activeRecipient.id)
              );
            });
            // console.log(
            //   "===>",
            //   isPresent,
            //   deletedSign,
            //   signTypes[kind],
            //   currentPageNumber,
            //   activeRecipient.id
            // );
            // console.log("***cfsdc",comp,"===",component)
            if (
              comp.componentId == signTypes[kind] &&
              comp.boundingBox[0] == component.boundingBox[0] &&
              comp.boundingBox[1] == component.boundingBox[1] &&
              comp.boundingBox[2] == component.boundingBox[2] &&
              comp.boundingBox[3] == component.boundingBox[3] &&
              isPresent < 0
            ) {
              // enqueueSnackbar("already present", { variant: "error" });
              return true;
            }
          }
        }
      }
    }
    // console.log("cfsdc=======end")
    return false;
  };
  const pdfPages = () => {
    Array.from(new Array(numPages), (_el, index) => {
      const page = currentPDF.getPage(index + 1);
      return (
        <Page
          scale={0.8}
          key={`page_${index + 1}`}
          pageNumber={index + 1}
          onClick={() => {
            // console.log("currentPage", index);
            setCurrentPage(index);
          }}
          onLoadSuccess={onPageLoadSuccess}
          onRenderSuccess={() => {
            // console.log("page render success called: ", index);
            onPageRenderSuccess(index);
          }}
        />
      );
    });
  };

  const applyToAll = (e: any) => {
    if (fabricSign.length > 0) duplicateComponent("sign");
    if (fabricInitials.length > 0) duplicateComponent("initial");
    if (fabricText.length > 0) duplicateComponent("text");
    if (fabricDate.length > 0) duplicateComponent("date");
    if (fabricSavedComponent.length > 0) duplicateSavedComponent();

    e.stopPropagation();
  };
  const duplicateComponent = (compType: string) => {
    console.log("duplicationg", compType);
    let index = currentDocumentIndex;
    let compId = documentDetails.documentDetailsSignComponent.filter(
      (type: any) => type.componentName === compType
    );
    let component, componentArray: any;
    switch (compType) {
      case "sign":
        component = fabricSign[0]?.componentList[0]?.component;
        componentArray = fabricSign;
        break;
      case "initial":
        component = fabricInitials[0]?.componentList[0]?.component;
        componentArray = fabricInitials;
        break;
      case "text":
        component = fabricText[0]?.componentList[0]?.component;
        componentArray = fabricText;
        break;
      case "date":
        component = fabricDate[0]?.componentList[0]?.component;
        componentArray = fabricDate;
        break;
    }
    let newTextSign = [...fabricSign];
    let newTextInitials = [...fabricInitials];
    let newText = [...fabricText];
    let newDate = [...fabricDate];
    componentArray.map((componentItem: any) => {
      console.log("duplicating", compType, componentArray);
      if (componentItem.pageNumber !== 0) return;
      component = componentItem?.componentList[0]?.component;
      let recipient = componentItem?.componentList[0]?.recipientId;
      let CurrentDate = new Date();
      let filterDate = CurrentDate.getSeconds();
      for (let i = 1; i < numPages; i++) {
        if (
          checkForDuplicateComponent(
            compType,
            componentArray,
            component,
            i,
            recipient
          )
        )
          continue;
        // @ts-ignore:next-line
        let newComp = fabric.util.object.clone(component);

        let pageData = {
          pageNumber: i,
          componentList: [
            {
              componentId: `canvas-${i}-${compId[0].id}`,
              recipientId: recipient,
              component: newComp,
              kind: compType,
              uniqueId: `${Math.ceil(
                Math.random() * 10000
              )}-${i}-${filterDate}`,
            },
          ],
        };

        newComp.componentId = pageData.componentList[0].componentId;
        newComp.uniqueId = pageData.componentList[0].uniqueId;
        newComp.recipientId = pageData.componentList[0].recipientId;
        switch (compType) {
          case "sign":
            // let newTextSign = [...fabricSign, pageData];
            // setFabricSign(newTextSign);
            newTextSign.push(pageData);
            break;
          case "initial":
            // let newTextInitials = [...fabricInitials, pageData];
            // setFabricInitials(newTextInitials);
            newTextInitials.push(pageData);
            break;
          case "text":
            // let newText = [...fabricText, pageData];
            // setFabricText(newText);
            newText.push(pageData);
            break;
          case "date":
            // let newText = [...fabricText, pageData];
            // setFabricText(newText);
            newDate.push(pageData);
            break;
        }
        console.log("data", index, i, numPages);

        fabricCanvas[index]?.fabric[i]?.add(newComp);
      }
    });
    switch (compType) {
      case "sign":
        setFabricSign(newTextSign);
        break;
      case "initial":
        setFabricInitials(newTextInitials);
        break;
      case "text":
        setFabricText(newText);
        break;
      case "date":
        setFabricDate(newDate);
        break;
    }
  };
  // console.log("Fabric sign", fabricSign);
  // console.log("Fabric ini", fabricInitials);
  // console.log("Fabric text", fabricText);
  // console.log("Fabric date", fabricDate);

  const duplicateSavedComponent = () => {
    let index = currentDocumentIndex;
    let newTextSign: any = [];
    let newTextInitials: any = [];
    let newText: any = [];
    let newDate: any = [];

    fabricSavedComponent.map((componentInfo: any) => {
      // newTextSign = [];
      // newTextInitials = [];
      // newText = [];
      // newDate = [];
      console.log("component -", componentInfo.recipientId);
      let componentArray: any;
      switch (componentInfo.kind) {
        case "sign":
          componentArray = fabricSign;
          break;
        case "initial":
          componentArray = fabricInitials;
          break;
        case "text":
          componentArray = fabricText;
          break;
        case "date":
          componentArray = fabricDate;
          break;
      }

      for (let i = 1; i < numPages; i++) {
        if (
          checkForSavedDuplicateComponent(
            componentInfo.kind,
            componentArray,
            componentInfo,
            i,
            componentInfo.recipientId
          )
        ) {
          continue;
        }
        console.log("==== FALSE", componentInfo.kind, componentInfo);
        let pageData = {
          pageNumber: i,
          componentList: [
            {
              componentId: `canvas-${i}-${componentInfo.componentId}`,
              recipientId: componentInfo.recipientId,
              componentImg: componentInfo.component,
              kind: componentInfo.kind,
              boundingBox: componentInfo.boundingBox,
              uniqueId: `${Math.ceil(
                Math.random() * 10000
              )}-${i}-${new Date().getSeconds()}`,
            },
          ],
        };

        switch (componentInfo.kind) {
          case "sign":
            newTextSign.push(pageData);
            break;
          case "initial":
            newTextInitials.push(pageData);
            break;
          case "text":
            newText.push(pageData);
            break;
          case "date":
            newDate.push(pageData);
            break;
        }
        // console.log("data", index, i, numPages);
        let zoomfactor = currentZoomLevel / 100;
        fabric.Image.fromURL(componentInfo.component, (img: any) => {
          img.set({
            left: componentInfo.boundingBox[0] * zoomfactor,
            top: componentInfo.boundingBox[1] * zoomfactor,
          });
          //  console.log("img", img);
          fabricCanvas[index]?.fabric[i].add(img);
        });
        // fabricCanvas[index]?.fabric[i].add(componentInfo.component);
      }

      // switch (componentInfo.kind) {
      //   case "sign":
      //     setFabricSign((prev: any) => [...prev, ...newTextSign]);
      //     break;
      //   case "initial":
      //     setFabricInitials((prev: any) => [...prev, ...newTextInitials]);
      //     break;
      //   case "text":

      //     setFabricText((prev: any) => [...prev, ...newText]);
      //     break;
      //   case "date":

      //     setFabricDate((prev: any) => [...prev, ...newDate]);
      //     break;
      // }
    });

    setFabricSign((prev: any) => [...prev, ...newTextSign]);

    setFabricInitials((prev: any) => [...prev, ...newTextInitials]);

    setFabricText((prev: any) => [...prev, ...newText]);

    setFabricDate((prev: any) => [...prev, ...newDate]);
  };

  useEffect(() => {
    if (
      fabricSign.length > 0 ||
      fabricInitials.length > 0 ||
      fabricText.length > 0 ||
      fabricDate.length > 0 ||
      fabricSavedComponent
    ) {
      //console.log(fabricSign)
      setShowApply(true);
    }
  }, [
    fabricSign,
    fabricInitials,
    fabricText,
    fabricDate,
    fabricSavedComponent,
  ]);

  const sortByOrder = (a: any, b: any) =>
    a.signingOrder < b.signingOrder ? -1 : 1;

  const deleteAll = () => {
    console.log("canvas", fabricCanvas);
    Array.from(new Array(numPages), (_el, index) => {
      let canvas = fabricCanvas[currentDocumentIndex]?.fabric[index];
      // console.log("c", canvas, fabricCanvas[currentDocumentIndex]);
      canvas.remove(...canvas.getObjects());
    });
    // if (typeof fabricCanvas[currentDocumentIndex]?.fabric === "object") {
    //   let canvas = fabricCanvas[currentDocumentIndex]?.fabric;
    //   canvas.remove(...canvas.getObjects());
    // } else {
    //   fabricCanvas[currentDocumentIndex]?.fabric?.map((canvas: any) => {
    //     canvas.remove(...canvas.getObjects());
    //   });
    // }

    setFabricInitials([]);
    setFabricSign([]);
    setFabricText([]);
    setFabricDate([]);
    showComponents();
  };

  const getSavedPlaceholderData = async (docmenHeaderId: any) => {
    let res = await axios.get(
      `${baseURL}/upload/get-sign-component-placeholder-document?userId=${sessionStorage.getItem(
        "userId"
      )}&companyId=${sessionStorage.getItem(
        "companyId"
      )}&documentDetailId=${docmenHeaderId}`,
      {
        headers: {
          key: `${headerKey}`,
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      }
    );
    return res?.data;
  };

  const isAllRecipientAdded = async () => {
    let dataPromise: any = [];
    enqueueSnackbar("Fetching sign data", { variant: "info" });

    console.log(
      " documentDetails.documentDetails ",
      documentDetails.documentDetails
    );
    documentDetails.documentDetails?.map((doc: any) => {
      // console.log("doc", doc);
      // if (doc.id !==currentDocument.id)
      dataPromise.push(getSavedPlaceholderData(doc.id));
    });
    let dataResult: any = await Promise.all(dataPromise);
    console.log("dataResult", dataResult);
    // dataResult.push(placeholderdata);
    // console.log("daatatat", dataResult, savedRecipientList);
    //  enqueueSnackbar("checking sign for all recipient", { variant: "info" });
    // dataResult.map((docData: any) => {
    //   docData?.documentPages?.map((docPage: any) => {
    //     docPage?.componentList?.map((comp: any) => {
    //       if (!recipientStatus[comp.recipientId]) {
    //         recipientStatus[comp.recipientId] = true;
    //       }
    //     });
    //   });
    // });
    return checkForRecipient(dataResult);
  };
  const checkForRecipient = (dataResult: any) => {
    let recipientStatus: any = {};
    let recipients: any = [];
    let filterStatus = false;
    savedRecipientList?.map((rec: any) => {
      recipients.push(rec.id);
    });
    const filter = dataResult.filter(
      (res: any) => res.documentDetailsId === null || res.documentPages === null
    );
    for (let [i, docData] of dataResult.entries()) {
      for (let [j, docPage] of docData?.documentPages?.entries()) {
        for (let [k, comp] of docPage?.componentList?.entries()) {
          // console.log("==>", recipientStatus);
          if (!recipientStatus[comp.recipientId]) {
            recipientStatus[comp.recipientId] = true;
          }
          if (Object.keys(recipientStatus).length === recipients.length)
            filterStatus = true;
        }
      }
    }
    if (filter.length !== 0) {
      filterStatus = false;
    }

    return filterStatus;
  };
  const disableSelectedComponent = (e: any) => {
    console.log("clicked outside", e);
    // let canvas = fabricCanvas[currentDocumentIndex].fabric[currentPage];
    // canvas.discardActiveObject().renderAll();
    if (["body", "li"].includes(e?.srcElement?.localName)) return;
    let canvas = fabricCanvas[currentDocumentIndex].fabric[currentPage];
    canvas.discardActiveObject().renderAll();
    if (
      e?.srcElement?.className &&
      e?.srcElement?.className?.includes("MuiBackdrop-invisible")
    )
      return;
    setHighLightEnableFeature({
      ...highLightEnableFeature,
      applyShadow: false,
      type: "none",
    });
  };
  const ref = useDetectClickOutside({ onTriggered: disableSelectedComponent });

  return (
    <>
      <DocumentNavigator
        type={type}
        setType={setType}
        handleDataSave={handleSave}
        currentDocument={currentDocument}
        isAllRecipientAdded={isAllRecipientAdded}
      />

      {loading || docLoading ? (
        <div className="bg-primary-1 mt-2 roundedcurrentDocumentId relative w-4/5 m-auto flex items-center justify-center p-4">
          <div className="w-full">
            <LinearProgress
              color="inherit"
              style={{
                backgroundColor: "red",
              }}
            />
          </div>
        </div>
      ) : (
        <div className="bg-primary-900 mt-0 roundedcurrentDocumentId relative w-full m-auto ">
          {/* <FullScreen className="my-component" handle={handleFullScreen}> */}
          {signLoading && (
            <div className="w-full">
              <LinearProgress
                color="inherit"
                style={{
                  backgroundColor: "#3366FF",
                }}
              />
            </div>
          )}

          <div className=" flex flex-col " style={{ border: "0px solid red " }}>
            <div className="flex w-full  items-center">
              <div className=" p-1 w-1/4">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Active Recipient
                  </InputLabel>
                  <Select
                    value={activeRecipient ? activeRecipient : " "}
                    label="Active recipient"
                    onChange={handleChangeRecipient}
                    size="small"
                  >
                    {savedRecipientList
                      ?.sort(sortByOrder)
                      .map((info: any, idx: any) => (
                        <MenuItem key={info.id} value={info}>
                          <span
                            className="dot"
                            style={{ backgroundColor: info.colorCode }}
                          ></span>{" "}
                          {info.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>

              <div
                className=" relative "
                style={{ border: "0px solid pink", left: "20%" }}
              >
                <div
                  className="text-center"
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "18px",
                    fontStyle: "italic",
                  }}
                >
                  {currentDocument && currentDocument.convertedDocumentName}
                  {totalDocumentsLength > 1 ? (
                    <span>
                      ({CurrentActiveDocument}/{totalDocumentsLength})
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="flex " style={{ border: "0px solid red" }}>
              {highLightEnableFeature.type == "text" ? (
                <div
                  className="flex w-[30%]"
                  style={{ border: "0px solid blue" }}
                >
                  <div className=" ml-1 w-[30%] ">
                    <FormControl fullWidth size="small">
                      <InputLabel id="font-select-label">font</InputLabel>
                      <Select
                        labelId="font-select-label"
                        value={textFont ? textFont : ""}
                        label="Font"
                        onChange={handleChangeFont}
                      >
                        {fonts.map((font: any, idx: any) => (
                          <MenuItem key={font.name} value={font}>
                            <p
                              className={`w-auto text-sm text-black ${font.fontClass} `}
                            >
                              {font.name}
                            </p>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div className=" ml-2 w-[30%]">
                    <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">
                        font Size
                      </InputLabel>
                      <Select
                        value={textSize ? textSize : "12"}
                        // value={"30"}
                        label="Font"
                        onChange={handleChangeSize}
                      >
                        {fontSizes.map((fontSize: any, idx: any) => (
                          <MenuItem key={fontSize} value={fontSize}>
                            {fontSize}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div className=" ml-2 w-[30%]">
                    <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">
                        Color
                      </InputLabel>
                      <Select
                        value={textColor ? textColor : ""}
                        label="Color"
                        onChange={handleChangeColor}
                      >
                        {colors.map((color: any, idx: any) => (
                          <MenuItem key={color} value={color}>
                            <span
                              className="dot dot-sm"
                              style={{ backgroundColor: color }}
                            ></span>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              ) : (
                <div className="w-[30%]"></div>
              )}

              <div
                style={{ border: "0px solid red " }}
                className=" flex w-[40%] justify-evenly "
              >
                {showApply && (
                  <div
                    className=" w-[45%] cursor-pointer"
                    // onClick={(e) => applyToAll(e)}
                    onClick={(e) => {
                      Swal.fire({
                        title:
                          "Are you sure you want to Apply Elements To All Page?",
                        text: "You won't be able to revert this!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes",
                      }).then((result: any) => {
                        if (result.isConfirmed) {
                          applyToAll(e);
                        }
                      });
                    }}
                  >
                    <Chip
                      label={`Apply Elements To All Page`}
                      className=" chip "
                    />
                  </div>
                )}
                <div className="w-[45%]" onClick={(e) => e.stopPropagation()}>
                  <Chip
                    label={`Active Page | ${currentPage + 1}`}
                    className="chip"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            className="relative p-1 w-full  mx-auto flex items-start justify-center"
            // style={{ height: "60vh" }}
          >
            <div
              className="flex items-center justify-center flex-col"
              // style={{ height: "60vh" }}
            >
              <div
                onClick={(e) => {
                  handleSelectItem("signature");
                  e.stopPropagation();
                }}
                style={{
                  boxShadow:
                    highLightEnableFeature.applyShadow &&
                    highLightEnableFeature.type === "signature"
                      ? "1px 1px 10px 4px #409A4D"
                      : "",
                }}
                className="w-full cursor-pointer flex items-center justify-center flex-col border border-black rounded-md p-2 mb-2 opacity-80"
              >
                <FaFileSignature color="black" size={25} />
                <p className="text-black mt-1 text-center text-[0.8rem]">
                  Signature
                </p>
              </div>
              <div
                onClick={(e) => {
                  handleSelectItem("initials");
                  e.stopPropagation();
                }}
                style={{
                  boxShadow:
                    highLightEnableFeature.applyShadow &&
                    highLightEnableFeature.type === "initials"
                      ? "1px 1px 10px 4px #409A4D"
                      : "",
                }}
                // onClick={() => makeInitalsCircle()}
                className="w-full cursor-pointer flex items-center justify-center flex-col border border-black rounded-md p-2 mb-2 opacity-80"
              >
                <FaSignature color="black" size={25} />
                <p className="text-black mt-1 text-center text-[0.8rem]">
                  Initials
                </p>
              </div>
              <div
                onClick={(e) => {
                  handleSelectItem("datesigned");
                  e.stopPropagation();
                }}
                style={{
                  boxShadow:
                    highLightEnableFeature.applyShadow &&
                    highLightEnableFeature.type === "datesigned"
                      ? "1px 1px 10px 4px #409A4D"
                      : "",
                }}
                // onClick={() => handleDate()}
                className="w-full cursor-pointer flex items-center justify-center flex-col border border-black rounded-md p-2 mb-2 opacity-80"
              >
                <CgCalendarDates color="black" size={25} />
                <p className="text-black mt-1 text-center text-[0.8rem]">
                  Date Signed
                </p>
              </div>
              <div
                onClick={(e) => {
                  handleSelectItem("text");
                  e.stopPropagation();
                }}
                style={{
                  boxShadow:
                    highLightEnableFeature.applyShadow &&
                    highLightEnableFeature.type === "text"
                      ? "1px 1px 10px 4px #409A4D"
                      : "",
                }}
                // onClick={() => handleText()}
                className="w-full cursor-pointer flex items-center justify-center flex-col border border-black rounded-md p-2 mb-2 opacity-80"
              >
                <RiText color="black" size={25} />
                <p className="text-black mt-1 text-center text-[0.8rem]">
                  Text
                </p>
              </div>

              <div
                onClick={(e) => {
                  handleSelectItem("reset");
                  e.stopPropagation();
                }}
                style={{
                  boxShadow:
                    highLightEnableFeature.applyShadow &&
                    highLightEnableFeature.type === "reset"
                      ? "1px 1px 10px 4px #409A4D"
                      : "",
                }}
                /* onClick={() => {
                  deleteAll();
                }} */

                className="w-full cursor-pointer flex items-center justify-center flex-col border border-black rounded-md p-2 mb-2 opacity-80"
              >
                <FaUndo color="black" size={25} />
                <p className="text-black mt-1 text-center text-[0.8rem]">
                  Reset All
                </p>
              </div>
              <div
                style={{ backgroundColor: "#941F23", color: "#fff" }}
                className="pl-4 rounded-md cursor-pointer pt-2 pb-2 pr-4 font-bold "
              >
                <div
                  onClick={(e) => {
                    deleteSelectedCanvasObject();
                    e.stopPropagation();
                  }}
                >
                  DELETE
                </div>
              </div>
            </div>
            <div className="w-3/4 ">
              <div
                className=" overflow-auto border-secondary mx-2 bg-primary-100 p-4 rounded-5 border-opacity-70 relative drop-shadow-md"
                style={{ height: "58vh" }}
              >
                <div
                  className="Example__container overflow-y-auto overflow-x-hidden"
                  // style={{ height: "429px" }}
                >
                  {/* <div className="Example__container__document__title">
                    <h3>
                      {currentDocument && currentDocument.convertedDocumentName}
                    </h3>
                  </div> */}
                  <div
                    onMouseMove={(e) => {
                      showCoords(e);
                    }}
                    onScroll={(e) => {
                      console.log("Scrolling", e);
                    }}
                    onWheel={(e) => {
                      console.log("Wheeling", e);
                    }}
                    onKeyDown={(e) => {
                      console.log("Wheeling keydown", e);
                    }}
                    onMouseOut={() => {
                      clearCoor();
                    }}
                    ref={ref}
                    id="CoordinatesData"
                    className="Example__container__document"
                  >
                    <Document
                      file={currentDocument.convertedDocumentPath}
                      onLoadSuccess={onDocumentLoadSuccess}
                      onLoadError={onDocumentLoadError}
                      onSourceError={onDocumentLoadError}
                      loading="Loading file..."
                      noData="No PDF file specified."
                    >
                      {Array.from(new Array(numPages), (_el, index) => {
                        return (
                          <Page
                            scale={zoomState}
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            // renderInteractiveForms={false}
                            renderAnnotationLayer={false}
                            renderTextLayer={false}
                            canvasRef={(ref) => {
                              /*   console.log(
                                  "refffffff",
                                  ref,
                                  fabricCanvas[currentDocumentIndex]
                                    .pageCanvasRefs[index]
                                ); */
                              // console.log(
                              //   "updating canvasref",
                              //   index,
                              //   fabricCanvas[currentDocumentIndex]
                              // );
                              // console.log("ref", ref);
                              if (fabricCanvas[currentDocumentIndex]) {
                                // console.log("updating canvasref good", index);
                                fabricCanvas[
                                  currentDocumentIndex
                                ].pageCanvasRefs[index] = ref;
                              } else {
                                // console.log("updating canvasref bad", index);
                              }
                            }}
                            // canvasBackground="transparent"
                            onClick={(e) => {
                              // console.log("currentPage", index);
                              console.log("currentPage e", e);
                              setCurrentPage(index);
                              let activeObj =
                                fabricCanvas[currentDocumentIndex].fabric[
                                  index
                                ].getActiveObject();
                              if (
                                highLightEnableFeature.applyShadow &&
                                !activeObj
                              ) {
                                AddselectedFeatureOnClickfunction(
                                  index,
                                  highLightEnableFeature.type
                                );
                              }
                            }}
                            onRenderSuccess={() => {
                              // console.log(
                              //   "page render success called: ",
                              //   index
                              // );
                              onPageRenderSuccess(index);
                            }}
                          />
                        );
                      })}
                    </Document>
                  </div>
                  {/* <p className="text-black"></p> */}
                </div>
              </div>
            </div>
            {/* highLightEnableFeature.applyShadow &&
                      highLightEnableFeature.type === "signature"
                        ? */}
            <div className="flex items-start justify-center flex-col relative">
              <div className="overflow-y-auto p-3  documentScrollerHeight">
                {documentDetails.documentDetails.map((doc: any, idx: any) => (
                  <div key={doc.id}>
                    <p className="text-black mt-2 font-semibold mb-1 break-all">
                      {idx + 1}
                    </p>
                    <div
                      style={{
                        borderRadius: "10px",
                        boxShadow:
                          CurrentActiveDocument - 1 === idx
                            ? "1px 1px 5px 1px #3366FF"
                            : "",
                        border:
                          CurrentActiveDocument - 1 === idx
                            ? "3px solid  #3366FF"
                            : "3px solid black",
                      }}
                      onClick={() => {
                        if (currentDocumentIndex === idx) {
                          // console.log("Reselecting current document only..");
                          return false;
                        }
                        setLoading(true);
                        handleSave(true);
                        setCurrentDocument(doc);
                        setActiveRecipient(savedRecipientList[0]);
                        updateCurrentdocumentIndex(doc);
                        setCurrentDocumentIndex(idx);
                      }}
                      className="bg-primary-bg w-[8rem] h-[10rem] cursor-pointer flex items-center justify-center flex-col border border-black rounded  mb-2 opacity-80"
                    >
                      <p
                        className="text-black mt-2  font-bold mb-1 break-all"
                        style={{
                          color:
                            CurrentActiveDocument - 1 === idx
                              ? "#3366FF"
                              : "black",
                        }}
                      >
                        {doc && doc.convertedDocumentName}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* </FullScreen> */}
        </div>
      )}
    </>
  );
};
