import { useNavigate } from "react-router-dom";
import "./style.css";

const CancelledPage = () => {
  return (
    <div className="parent-card">
      <div className="success-card">
        <div
          style={{
            borderRadius: "200px",
            paddingLeft: "45%",
            background: "rgb(255 224 224)",
            margin: 0,
          }}
        >
          <i className="checkmark-can">X</i>
        </div>
        <span style={{ color: "#000000" }}>
          <h1>Transaction Failed or Cancelled</h1>
          <p>
            Please Start it again by{" "}
            <a href="https://app.easysign.us.com">Clicking here</a>
          </p>
        </span>
      </div>
    </div>
  );
};

export default CancelledPage;
