import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import React from "react";

interface CheckBoxProps {
  label: string;
  checked: boolean;
  disabled: boolean;
  handleCheckBoxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CheckBox: React.FC<CheckBoxProps> = ({
  checked,
  handleCheckBoxChange,
  disabled,
  label,
}) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={(e) => handleCheckBoxChange(e)}
            disabled={disabled}
          />
        }
        label={label}
        className="text-black"
      />
    </FormGroup>
  );
};
