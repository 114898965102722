import React, { useState } from "react";
import {
  SiMicrosoftword,
  SiMicrosoftexcel,
  SiMicrosoftpowerpoint,
} from "react-icons/si";
import { AiFillFileText, AiOutlineFilePdf } from "react-icons/ai";
import { BsFillCameraVideoFill } from "react-icons/bs";
import { getFileType } from "./FileUpload";
import { MdVisibility, MdGraphicEq, MdDelete } from "react-icons/md";
import { Document, Page, pdfjs } from "react-pdf";
import { IconType } from "react-icons/lib";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface FileViewProps {
  file: any;
  showDelete: boolean;
  handleDelete?: (id: any) => void;
}

const SingleFileView: React.FC<FileViewProps> = ({
  file,
  showDelete,
  handleDelete,
}) => {
  //console.log("FILE", file, getFileType(file?.documentsOriginalName),getFileType(file?.documentsOriginalName) === "jpeg");

  return (
    <>
      {/* { getFileType(file?.documentsOriginalName) === "png" ||
              getFileType(file?.documentsOriginalName) === "jpeg" ||
              getFileType(file?.documentsOriginalName) === "jpg" && (
              <div
                key={file.id}
                className="flex items-center justify-between w-full mt-2 border border-black rounded-md p-2 text-black cursor-pointer"
                onClick={() =>
                  window.open(`${file.convertedDocumentPath}`, "_Blank")
                }
              >
                <div className="px-1 flex items-center justify-center">
                  <AiFillFileText size={20} />
                  <p className="flex items-center justify-center text-base font-semibold ml-2">
                    {file?.documentsOriginalName}
                  </p>
                </div>
                <div className="flex items-center justify-center ml-4 relative">
                  {showDelete && (
                    <MdVisibility size={20} className="text-black mr-1" />
                  )}
                </div>
              </div>
            )} */}
      {(getFileType(file?.documentsOriginalName) === "png" ||
        getFileType(file?.documentsOriginalName) === "jpeg" ||
        getFileType(file?.documentsOriginalName) === "jpg") && (
        <BaseFileView
          file={file}
          showDelete={showDelete}
          Icon={AiFillFileText}
          handleDelete={handleDelete}
        />
      )}

      {/* {getFileType(file?.documentsOriginalName) === "mp4" && (
              <div
                key={file.id}
                className="flex items-center justify-between w-full mt-2 border border-black rounded-md p-2 text-black cursor-pointer"
                onClick={() =>
                  window.open(`${file.convertedDocumentPath}`, "_Blank")
                }
              >
                <div className="px-1 flex items-center justify-center">
                  <BsFillCameraVideoFill size={20} />

                  <p className="flex items-center justify-center text-base font-semibold ml-2">
                    {file?.documentsOriginalName}
                  </p>
                </div>
                <div className="flex items-center justify-center ml-4 relative">
                  {showDelete && (
                    <MdVisibility size={20} className="text-black mr-1" />
                  )}
                </div>
              </div>
            )} */}
      {getFileType(file?.documentsOriginalName) === "mp4" && (
        <BaseFileView
          file={file}
          showDelete={showDelete}
          Icon={BsFillCameraVideoFill}
          handleDelete={handleDelete}
        />
      )}
      {getFileType(file?.documentsOriginalName) === "pdf" && (
        <BaseFileView
          file={file}
          showDelete={showDelete}
          Icon={AiOutlineFilePdf}
          handleDelete={handleDelete}
        />
      )}
      {/* {(getFileType(file?.documentsOriginalName) === "doc" ||
              getFileType(file?.documentsOriginalName) === "docx") && (
              <div
                key={file.id}
                className="flex items-center justify-between w-full mt-2 border border-black rounded-md p-2 text-black cursor-pointer"
                onClick={() =>
                  window.open(`${file.convertedDocumentPath}`, "_Blank")
                }
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
              >
                <div className="px-1 flex items-center justify-center">
                  <SiMicrosoftword size={20} />

                  <p className="flex items-center justify-center text-base font-semibold ml-2">
                    {file?.documentsOriginalName}
                  </p>
                </div>
                <div className="flex items-center justify-center ml-4 relative">
                  {showDelete && (
                    <>
                    <MdVisibility size={20} className="text-black mr-1" />
                    <MdDelete size={20} className="" onClick={(e)=>{  e.stopPropagation(); handleDelete && handleDelete(file.id)}}/>
                    </>
                  )}
                  {
                    showDelete &&  isHovering && (<div>HOVERING </div>)
                  }
                </div>
              </div>
            )} */}
      {(getFileType(file?.documentsOriginalName) === "doc" ||
        getFileType(file?.documentsOriginalName) === "docx") && (
        <BaseFileView
          file={file}
          showDelete={showDelete}
          Icon={SiMicrosoftword}
          handleDelete={handleDelete}
        />
      )}
      {/* {(getFileType(file?.documentsOriginalName) === "xls" ||
              getFileType(file?.documentsOriginalName) === "xlsx") && (
              <div
                key={file.id}
                className="flex items-center justify-between w-full mt-2 border border-black rounded-md p-2 text-black cursor-pointer"
                onClick={() =>
                  window.open(`${file.convertedDocumentPath}`, "_Blank")
                }
              >
                <div className="px-1 flex items-center justify-center">
                  <SiMicrosoftexcel size={20} />

                  <p className="flex items-center justify-center text-base font-semibold ml-2">
                    {file?.documentsOriginalName}
                  </p>
                </div>
                <div className="flex items-center justify-center ml-4 relative">
                  {showDelete && (
                    <MdVisibility size={20} className="text-black mr-1" />
                  )}
                </div>
              </div>
            )} */}
      {(getFileType(file?.documentsOriginalName) === "xls" ||
        getFileType(file?.documentsOriginalName) === "xlsx") && (
        <BaseFileView
          file={file}
          showDelete={showDelete}
          Icon={SiMicrosoftexcel}
          handleDelete={handleDelete}
        />
      )}
      {/* {(getFileType(file?.documentsOriginalName) === "ppt" ||
              getFileType(file?.documentsOriginalName) === "pptx") && (
              <div
                key={file.id}
                className="flex items-center justify-between w-full mt-2 border border-black rounded-md p-2 text-black cursor-pointer"
                onClick={() =>
                  window.open(`${file.convertedDocumentPath}`, "_Blank")
                }
              >
                <div className="px-1 flex items-center justify-center">
                  <SiMicrosoftpowerpoint size={20} />

                  <p className="flex items-center justify-center text-base font-semibold ml-2">
                    {file?.documentsOriginalName}
                  </p>
                </div>
                <div className="flex items-center justify-center ml-4 relative">
                  {showDelete && (
                    <MdVisibility size={20} className="text-black mr-1" />
                  )}
                </div>
              </div>
            )} */}

      {(getFileType(file?.documentsOriginalName) === "ppt" ||
        getFileType(file?.documentsOriginalName) === "pptx") && (
        <BaseFileView
          file={file}
          showDelete={showDelete}
          Icon={SiMicrosoftpowerpoint}
          handleDelete={handleDelete}
        />
      )}
      {/* {getFileType(file?.documentsOriginalName) === "mp3" && (
              <div
                key={file.id}
                className="flex items-center justify-between w-full mt-2 border border-black rounded-md p-2 text-black cursor-pointer"
                onClick={() =>
                  window.open(`${file.convertedDocumentPath}`, "_Blank")
                }
              >
                <div className="px-1 flex items-center justify-center">
                  <MdGraphicEq size={20} />

                  <p className="flex items-center justify-center text-base font-semibold ml-2">
                    {file?.documentsOriginalName}
                  </p>
                </div>
                <div className="flex items-center justify-center ml-4 relative">
                  {showDelete && (
                    <MdVisibility size={20} className="text-black mr-1" />
                  )}
                </div>
              </div>
            )} */}
      {getFileType(file?.documentsOriginalName) === "mp3" && (
        <BaseFileView
          file={file}
          showDelete={showDelete}
          Icon={MdGraphicEq}
          handleDelete={handleDelete}
        />
      )}
    </>
  );
};

interface FilePreview {
  file: any;
}

const FilePreView: React.FC<FilePreview> = ({ file }) => {
  return (
    <>
      <Document
        file={file.convertedDocumentPath}
        loading="Loading file..."
        noData="No PDF file specified."
      >
        <Page scale={0.2} pageNumber={1} />
      </Document>
    </>
  );
};

interface BaseFileView {
  file: any;
  showDelete: boolean;
  Icon: IconType;
  handleDelete?: (id: any) => void;
}

const BaseFileView: React.FC<BaseFileView> = ({
  file,
  showDelete,
  Icon,
  handleDelete,
}) => {
  // console.log("File BASEFILEVIEW",file)
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };
  return (
    <div
      key={file.id}
      className="flex items-center justify-between w-full mt-2 border border-black rounded-md p-2 text-black cursor-pointer"
      onClick={() => window.open(`${file.convertedDocumentPath}`, "_Blank")}
    >
      <div className="px-1 flex items-center justify-center">
        <Icon size={20} />

        <p className="flex items-center justify-center text-base font-semibold ml-2">
          {file?.documentsOriginalName}
        </p>
      </div>
      <div className="flex items-center justify-center ml-4 relative">
        {showDelete && (
          <>
            <MdVisibility
              size={20}
              className="text-black mr-1"
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
            />
            <MdDelete
              size={20}
              className=""
              onClick={(e) => {
                e.stopPropagation();
                // console.log("FILE",file)
                handleDelete && handleDelete(file?.id);
              }}
            />
          </>
        )}
        {showDelete && isHovering && (
          <div className=" absolute bg-primary-bg w-[10rem] h-[14rem] cursor-pointer flex items-center justify-center flex-col  rounded p-4 mb-2 -top-6 left-10">
            <FilePreView file={file} />{" "}
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleFileView;
