import { FcUpload } from "react-icons/fc";
import { MdOutlineClose } from "react-icons/md";
import "./FileUploader.css";

export const fileTypes = [
  "jpg",
  "jpeg",
  "png",
  "BMP",
  "bmp",
  "pdf",
  "doc",
  "docx",
  "xls",
  "xlsx",
  "mp4",
];

export const uploadType = ["jpg", "jpeg", "png"];

export const getFileType = (fileName: string) => {
  const fileExt = fileName.split(".").pop();

  if (fileExt) {
    if (fileTypes.includes(fileExt)) {
      return fileExt;
    }
  }
  return "file";
};

interface FileUploadProps {
  multiple: boolean;
  format: boolean;
  DisplayText: React.ReactNode;
  files: any;
  handleFileUpload: any;
  setFiles: any;
  type?: string;
}

const FileUpload: React.FC<FileUploadProps> = ({
  multiple,
  DisplayText,
  format,
  files,
  handleFileUpload,
  setFiles,
  type,
}) => {
  const handleInitialFileremoval = (file: any) => {
    setFiles(files.filter((f: any) => f !== file));
  };

  const handleFileSubmit = async () => {
    if (files.length === 0) {
      // enqueueSnackbar(`Please upload file to proceed`, { duration: 3000 });
    } else {
      // if (handleStudySubmit) {
      //   setUploadedFile(true);
      //   await extrafileuploadstatus(false);
      // } else {
      //   setUploadedFile(true);
      //   if (files.length === 0) {
      //     handleAllowFileUpload(true);
      //   }
      // }
    }
  };

  return (
    <div className={"w-full mt-3 opacity-60"}>
      <section className="progress-area max-h-400 overflow-scroll">
        {files &&
          files.length > 0 &&
          files.map((file: any, _idx: any) => {
            return (
              <li className="row mt-1 px-1">
                <i className="fas fa-file-alt" />
                <div className="content upload">
                  <div className="details">
                    <div className="flex flex-col">
                      <span
                        className="name text-black font-semibold"
                        style={{ fontSize: "0.8em" }}
                      >
                        {file?.name}
                      </span>
                      <span
                        className="text-sm text-black"
                        style={{ fontSize: "0.8em" }}
                      >
                        {file?.size} kb
                      </span>
                    </div>
                    <div className="flex items-center justify-between mb-0.5 cursor-pointer">
                      <MdOutlineClose
                        size={15}
                        onClick={() => handleInitialFileremoval(file)}
                        color="black"
                      />
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
      </section>
      <div className="w-full rounded-lg p-0.5 my-2">
        <label style={{ cursor: "pointer" }}>
          <div className="upForm p-3">
            <input
              className="file-input text-black"
              type="file"
              name="file"
              hidden
              multiple={multiple}
              onChange={(e) => handleFileUpload(e, type)}
            />
            {DisplayText}
            <FcUpload size={25} />
          </div>
        </label>
      </div>
      {format ? (
        <p className="text-white text-xs p-0.5 opacity-90">
          Accepted files format {uploadType.join(", ")} files
        </p>
      ) : null}
    </div>
  );
};

export default FileUpload;
