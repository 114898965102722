import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Login } from "../components/Login";
import { Main } from "../components/Main";
import { Manage } from "../components/Manage";
import { Thankou } from "../components/Recepientsign/Thankyou";
import { Signs } from "../components/Signs";
import { RecepientSign } from "../components/Recepientsign/RecepientSign";
import routerConfig from "./config";
import Protected from "./protectedRoute";
import { DocumentDetails } from "../components/Documentdetails/DocumentDetails";
import { Registration } from "../components/Registration";
import { VerifyUser } from "../components/VerifyUser";
import { ResendEmailVerification } from "../components/ResendEmailForVerification";

import Pay from "../components/stripe/pay";
import SuccessPage from "../components/stripe/checkoutForm/successPage";
import CancelledPage from "../components/stripe/checkoutForm/cancelledPage";
import { LinkExpiredMsg } from "../components/Recepientsign/LinkExpiredMsg";
import { AlreadySignedMsg } from "../components/Recepientsign/AlreadySignedMsg";

import AppLogout from "./AppLogout";
import { OTPVerification } from "../components/OTPVerification";
import MyPay from "../components/MyPay/MyPay";
interface RoutingProps {}

export const Routing: React.FC<RoutingProps> = () => {
  let recipientType = {
    type: 0,
    setType: "",
  };

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/registration" element={<Registration />} />

      <Route path="/otp_verification" element={<OTPVerification />} />

      <Route
        key="/home"
        path="/home"
        element={
          <Protected>
            <AppLogout>
              <Main />{" "}
            </AppLogout>
          </Protected>
        }
      />
      <Route
        key="/sign"
        path="/sign"
        element={
          <Protected>
            <AppLogout>
              <Signs />{" "}
            </AppLogout>
          </Protected>
        }
      />
      <Route
        key="/manage"
        path="/manage"
        element={
          <Protected>
            <AppLogout>
              <Manage />{" "}
            </AppLogout>
          </Protected>
        }
      />
      <Route
        key="/documentDetails"
        path="/documentDetails"
        element={
          <Protected>
            <AppLogout>
              <DocumentDetails />{" "}
            </AppLogout>
          </Protected>
        }
      />

      <Route key="*" path="*" element={<Login />} />
      <Route
        path="/sign-document"
        element={<RecepientSign {...recipientType} />}
      />
      <Route path="/thankyou" element={<Thankou />} />
      <Route path="/verifyuser" element={<VerifyUser />} />
      <Route
        key="/resendforverify"
        path="/resendforverify"
        element={<ResendEmailVerification />}
      />
      <Route path="/pay" element={<Pay />} />
      <Route path="/success" element={<SuccessPage />} />

      <Route path="/cancelled" element={<CancelledPage />} />
      <Route path="/LinkExpiredMsg" element={<LinkExpiredMsg />} />
      <Route path="/AlreadySignedMsg" element={<AlreadySignedMsg />} />

      <Route path="/myPay" element={<MyPay />} />

      {/* <Route path="/manage" element={ isLoggedIn ? <Manage />  :  <Login/>} />
      <Route path="/sign" element={ isLoggedIn ? <Signs />  :  <Login/>} />
      <Route path="/documentDetails" element={ isLoggedIn ? <Signs />  :  <Login/>} />
      {/* <Route path="/sign-document" element={ <RecepientSign{...recipientType} />} /> }
      <Route path="/thankyou" element={ isLoggedIn ? <Thankou />  :  <Login/>} />
      <Route path="/" element={ isLoggedIn ? <Main />  :  <Login/>} />
      <Route path="*" element={<Navigate replace to="/login" />} /> */}

      {/* {routerConfig.map((Rut: any) => (
        <Route key={Rut.path} path={Rut.path} element={<Rut.Component />} />
      ))} */}
    </Routes>
  );
};
