import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UploadService from "./documentUploadService";
import { useSnackbar } from "notistack";
import { Menu, MenuItem, Button } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";

// Declare gapi to avoid TypeScript errors
declare const gapi: any;

const CLIENT_ID = "";
const API_KEY = "";

const SCOPES = "https://www.googleapis.com/auth/drive.file";

const DocumentUpload: React.FC = () => {
  const [currentFile, setCurrentFile] = useState<File | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const [message, setMessage] = useState<string>("");
  const [fileList, setFileList] = useState<FileList | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    // Load the Google API library
    const loadGoogleAPI = () => {
      const script = document.createElement("script");
      script.src = "https://apis.google.com/js/api.js";
      script.onload = () => {
        gapi.load("client:auth2", initGoogleDriveAPI);
      };
      document.body.appendChild(script);
    };
    loadGoogleAPI();
  }, []);

  const initGoogleDriveAPI = () => {
    gapi.client
      .init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: [
          "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
        ],
        scope: SCOPES,
      })
      .then(() => {
        console.log("Google Drive API initialized");
      })
      .catch((error: any) => {
        console.error("Error initializing Google API", error);
      });
  };

  const signInToGoogleDrive = () => {
    const authInstance = gapi.auth2.getAuthInstance();
    authInstance.signIn().then(() => {
      const userEmail = sessionStorage.getItem("userEmail");
      if (userEmail) {
        enqueueSnackbar(`Signed in as ${userEmail}`, { variant: "success" });
      }
      handleChooseFromGoogleDrive();
    });
  };

  const handleChooseFromGoogleDrive = () => {
    handleMenuClose();
    gapi.auth2
      .getAuthInstance()
      .signIn()
      .then(() => {
        gapi.client.drive.files
          .list({
            pageSize: 10,
            fields: "nextPageToken, files(id, name)",
          })
          .then((response: any) => {
            console.log("Files from Google Drive: ", response.result.files);
          });
      });
  };

  const buttonStyle = {
    backgroundColor: currentFile
      ? isHovered
        ? "#0056b3"
        : "#007bff"
      : "#c0c0c0",
    color: currentFile ? "white" : "black",
    border: `1px solid ${currentFile ? "transparent" : "yellow"}`,
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: currentFile ? "pointer" : "not-allowed",
    transition: "background-color 0.2s, color 0.2s, box-shadow 0.2s",
    fontSize: "15px",
    boxShadow: currentFile
      ? isHovered
        ? "0 4px 8px rgba(255, 255, 0, 0.3)"
        : "none"
      : "none",
  };

  const acceptedFileTypes = [
    "application/pdf",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files && files.length > 0) {
      const selectedFile = files[0];
      if (acceptedFileTypes.includes(selectedFile.type)) {
        setCurrentFile(selectedFile);
        setFileList(files);
        setProgress(0);
      } else {
        enqueueSnackbar("Unsupported file type. Please upload a valid file.", {
          variant: "error",
        });
      }
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const selectedFile = files[0];
      if (acceptedFileTypes.includes(selectedFile.type)) {
        setCurrentFile(selectedFile);
        setFileList(files);
        setProgress(0);
      } else {
        enqueueSnackbar("Unsupported file type. Please upload a valid file.", {
          variant: "error",
        });
      }
    }
  };

  const upload = () => {
    if (!currentFile) {
      enqueueSnackbar("Please select a file before uploading.", {
        variant: "error",
      });
      return;
    }

    setProgress(0);

    UploadService.upload(fileList!, (event: any) => {
      setProgress(Math.round((100 * event.loaded) / event.total));
    })
      .then((response) => {
        if (response.data === "Special Characters Found") {
          enqueueSnackbar("Please remove special characters", {
            variant: "error",
          });
        } else {
          setMessage(response.data.message);
          navigate("/manage");
        }
      })
      .catch((err) => {
        setProgress(0);
        if (err.response && err.response.data && err.response.data.message) {
          setMessage(err.response.data.message);
        } else {
          setMessage("Could not upload the file!");
        }
        setCurrentFile(null);
      });
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChooseFromComputer = () => {
    handleMenuClose();
    fileInputRef.current?.click();
  };

  const handleChoose = () => {
    handleMenuClose();
    fileInputRef.current?.click();
  };

  // const handleChooseFromGoogleDrive = () => {
  //   handleMenuClose();
  //   enqueueSnackbar("Google Drive integration not implemented yet.", {
  //     variant: "info",
  //   });
  // };

  const files = fileList ? [...fileList] : [];

  return (
    <>
      <div>
        <div
          className="flex items-center justify-center flex-col"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          style={{
            border: "2px dashed #ccc",
            padding: "20px",
            borderRadius: "10px",
            width: "100%",
            marginTop: "20px",
            marginBottom: "20px",
            textAlign: "center",
            cursor: "pointer",
            boxShadow: isHovered
              ? "0px 0px 15px rgba(255, 255, 0, 0.3)"
              : "none",
            transition: "box-shadow 0.2s ease",
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={handleChoose}
        >
          <p style={{ marginBottom: "10px", color: "#000" }}>
            Drag & Drop PDF, Word, or Excel files here, or click to select files
          </p>
          <p style={{ marginBottom: "10px", color: "#000" }}>
            (Accepted file types: PDF, Word, Excel)
          </p>
          {files.length > 0 && (
            <table
              style={{
                color: "black",
                width: "100%",
                borderCollapse: "collapse",
              }}
            >
              <thead>
                <tr>
                  <th>Sr.</th>
                  <th>File Name</th>
                  <th>File Size</th>
                </tr>
              </thead>
              <tbody>
                {files.map((file, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{file.name}</td>
                    <td>{(file.size / 1024).toFixed(2)} KB</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {!currentFile && (
            <div style={{ marginTop: "10px", marginBottom: "10px" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleMenuClick}
                endIcon={<ArrowDropDown />}
              >
                Choose Files
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleChooseFromComputer}>
                  Choose from computer
                </MenuItem>
                <MenuItem onClick={handleChooseFromGoogleDrive}>
                  Choose from Google Drive
                </MenuItem>
              </Menu>
            </div>
          )}
          {currentFile && (
            <div style={{ marginTop: "20px", marginBottom: "10px" }}>
              <button
                style={buttonStyle}
                onClick={upload}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                Upload
              </button>
            </div>
          )}
        </div>

        {currentFile && (
          <div className="w-full bg-gray rounded-full mt-4 mb-2">
            <div
              className="bg-green text-center p-0.5 leading-none rounded-l-full"
              style={{ width: progress + "%" }}
            >
              {progress}%
            </div>
          </div>
        )}

        {message && <p>{message}</p>}
      </div>
    </>
  );
};

export default DocumentUpload;
