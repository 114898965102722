export const documentDetailsReducer = (state: any, action: any): any => {
  switch (action.type) {
    case "SET_RECIPIENT_SUGGESTIONS":
      return {
        ...state,
        recipientSuggestions: action.payload,
        recipientSuggestionsLoading: false,
        loading: false,
      };

    case "GET_ALLPENDINGDOCUMENT":
      return {
        ...state,
      };

    case "SET_LOADING_SUGESTIONS":
      return {
        ...state,
        recipientSuggestionsLoading: true,
      };

    case "GET_DOCUMENTDETAILS":
      return {
        ...state,
        documentDetails: action.payload,
        loading: false,
      };

    case "SAVED_RECIPIENT_LIST":
      return {
        ...state,
        savedRecipientList: action.payload,
        loading: false,
      };
    case "PLACEHOLDER_DATA":
      return {
        ...state,
        placeholderdata: action.payload,
        loading: false,
      };
    case "LOADING":
      return {
        ...state,
        loading: true,
      };

    case "LOADING_FALSE":
      return {
        ...state,
        loading: false,
      };

    case "PLACEHOLDER_LOADING":
      return {
        ...state,
        placeholderLoading: true,
      };

    case "PLACEHOLDER_LOADING_FALSE":
      return {
        ...state,
        placeholderLoading: false,
      };

    default:
      return state;
  }
};
