import { useEffect, useState } from "react";
// import { useSnackbar } from "notistack";

const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];
interface ParentCompProps {
  childComp?: React.ReactNode;
}
const AppLogout: React.FC<ParentCompProps> = (props: any) => {
  const { children } = props;
  let timer: any;
  // const { enqueueSnackbar } = useSnackbar();

  // this function sets the timer that logs out the user after 10 secs
  const handleLogoutTimer = () => {
    let time = 600; // in second
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
      // Listener clean up. Removes the existing event listener from the window
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      // logs out user
      logoutAction();
    }, time * 1000);
  };

  // this resets the timer if it exists.
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  // when component mounts, it adds an event listeners to the window
  // each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc, the timer to logout user after 10 secs of inactivity resets.
  // However, if none of the event is triggered within 10 secs, that is app is inactive, the app automatically logs out.
  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
  }, []);

  // logs out user by clearing out auth token in localStorage and redirecting url to /signin page.
  const logoutAction = () => {
    localStorage.clear();
    sessionStorage.clear();
    // enqueueSnackbar("Opps Logged out", { variant: "warning" });
    window.location.pathname = "/signin";
    alert(
      "Your user session is expiring. If your session expires, all your unsaved data will be lost."
    );
  };

  return children;
};

export default AppLogout;
