import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { blue } from "@mui/material/colors";

export const VerifyUser: React.FC = () => {
  const baseURL = process.env.REACT_APP_API_URL;
  const headerKey = process.env.REACT_APP_API_HEADER_KEY;

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [message, setMessage] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [buttonPath, setButtonPath] = useState("");

  useEffect(() => {
    getAuditData();
  }, []);

  const getAuditData = async () => {
    const otp = searchParams.get("otp");
    const email = searchParams.get("email");

    try {
      const response = await axios.post(
        `${baseURL}/auth/verifyRegisteredUser/${otp}/${email}`,
        {
          method: "POST",
          headers: {
            key: `${headerKey}`,
          },
        }
      );

      const { data } = response;

      if (data === "User verified") {
        setMessage(
          "The user is successfully verified, and the status is updated in the database."
        );
        setButtonText("Login");
        setButtonPath("/login");
      } else if (data === "Verification link expired") {
        setMessage(
          "The OTP provided does not match the one stored in the cache (expired/invalid)."
        );
        setButtonText("Resend Verification Code/Mail");
        setButtonPath(`/resendforverify?email=${email}`);
      } else if (data === "User already verified!") {
        setMessage("The user has already been verified before.");
        setButtonText("Login");
        setButtonPath("/login");
      } else if (data === "User Not Found!") {
        setMessage("No user with the provided email exists in the system.");
        setButtonText("Sign Up");
        setButtonPath("/registration");
      } else {
        setMessage("An error occurred during the process.");
        setButtonText("Login");
        setButtonPath("/login");
      }
    } catch (err) {
      console.error(err);
      setMessage("An error occurred during the process.");
      setButtonText("Login");
      setButtonPath("/login");
    }
  };

  return (
    <div className="py-1 px-4 lg:px-15">
      <div
        className="bg-white py-10 my-10 border-2"
        style={{ backgroundColor: "white", boxShadow: "1px 1px 3px grey" }}
      >
        <div className="text-center">
          <p className="text-black opacity-80 mb-4">{message}</p>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate(buttonPath)}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  );
};
