import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";

interface ProfilePopupProps {
  open: boolean;
  onClose: () => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props: any) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export const ProfilePopup: React.FC<ProfilePopupProps> = ({
  open,
  onClose,
}) => {
  const [profileImage, setProfileImage] = useState<string>(
    "https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-PNG-Images.png"
  );
  const [userName, setUserName] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  useEffect(() => {
    const storedName = sessionStorage.getItem("userName") || "";
    const storedEmail = sessionStorage.getItem("userEmail") || "";
    setUserName(storedName);
    setEmail(storedEmail);
  }, []);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target && e.target.result) {
          setProfileImage(e.target.result as string);
        }
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      scroll="paper"
      fullWidth={true}
      maxWidth="sm"
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
        Profile
      </BootstrapDialogTitle>
      <Divider />
      <DialogContent dividers>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box position="relative">
            <Avatar
              alt="Profile"
              src={profileImage}
              sx={{ width: 100, height: 100 }}
            />
            <input
              accept="image/*"
              type="file"
              style={{ display: "none" }}
              id="profile-image-input"
              onChange={handleImageChange}
            />
            <label htmlFor="profile-image-input">
              <IconButton
                color="primary"
                aria-label="edit profile image"
                component="span"
                sx={{
                  position: "absolute",
                  bottom: -5,
                  right: -5,
                  backgroundColor: "white",
                }}
              >
                <EditIcon />
              </IconButton>
            </label>
          </Box>
          <TextField
            margin="dense"
            label="Name"
            fullWidth
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            variant="outlined"
            sx={{ marginTop: 2 }}
          />
          <TextField
            margin="dense"
            label="Email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
            sx={{ marginTop: 2 }}
          />
          <TextField
            margin="dense"
            label="Days left"
            fullWidth
            // value="20 days left"
            variant="outlined"
            disabled
            sx={{ marginTop: 2 }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          Save
        </Button>
        <Button onClick={onClose} variant="contained" color="warning">
          Close
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};
