import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import { useSnackbar } from "notistack";
import axios from "axios"; // Import axios

interface CookieConsentPopupProps {
  modal: boolean;
  setShowModal: (value: boolean) => void;
  onAccept: (cookiePreferences: CookiePreferences) => void;
}

interface CookiePreferences {
  essential: boolean;
  analytics: boolean;
  marketing: boolean;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props: any) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export const CookieConsentPopup: React.FC<CookieConsentPopupProps> = ({
  modal,
  setShowModal,
  onAccept,
}) => {
  const [cookiePreferences, setCookiePreferences] = useState<CookiePreferences>(
    {
      essential: true,
      analytics: false,
      marketing: false,
    }
  );

  const { enqueueSnackbar } = useSnackbar();

  const baseURL = process.env.REACT_APP_API_URL;

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCookiePreferences({
      ...cookiePreferences,
      [event.target.name]: event.target.checked,
    });
  };

  const handleAccept = async () => {
    if (cookiePreferences.essential === false) {
      enqueueSnackbar("Essential cookies cannot be disabled.", {
        variant: "error",
      });
      return;
    }

    try {
      const response = await axios.post(
        `${baseURL}/api/save-cookie-preferences`,
        cookiePreferences,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200 && response.data.status === "success") {
        sessionStorage.setItem(
          "cookiePreferences",
          JSON.stringify(cookiePreferences)
        );
        onAccept(cookiePreferences);
        enqueueSnackbar("Cookie preferences saved successfully.", {
          variant: "success",
        });
        setShowModal(false);
      } else {
        enqueueSnackbar(
          "Failed to save cookie preferences. Please try again.",
          { variant: "error" }
        );
      }
    } catch (error) {
      console.error("Error saving cookie preferences:", error);
      enqueueSnackbar("An error occurred. Please try again later.", {
        variant: "error",
      });
    }
  };

  return (
    <BootstrapDialog
      onClose={() => setShowModal(false)}
      aria-labelledby="customized-dialog-title"
      open={modal}
      scroll="paper"
      fullWidth={true}
      maxWidth="sm"
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={() => setShowModal(false)}
      >
        Cookie Consent
      </BootstrapDialogTitle>
      <Divider />
      <DialogContent dividers>
        <Typography variant="body1" paragraph>
          We use cookies to enhance your browsing experience, serve personalized
          content, and analyze site traffic. You can customize your preferences
          below. Essential cookies are necessary for the website to function and
          cannot be switched off in our systems.
        </Typography>
        <Box display="flex" flexDirection="column">
          <FormControlLabel
            control={
              <Switch
                checked={cookiePreferences.essential}
                name="essential"
                disabled
              />
            }
            label="Essential Cookies (Always On)"
          />
          <FormControlLabel
            control={
              <Switch
                checked={cookiePreferences.analytics}
                onChange={handleSwitchChange}
                name="analytics"
              />
            }
            label="Analytics Cookies"
          />
          <FormControlLabel
            control={
              <Switch
                checked={cookiePreferences.marketing}
                onChange={handleSwitchChange}
                name="marketing"
              />
            }
            label="Marketing Cookies"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAccept} variant="contained" color="primary">
          Accept
        </Button>
        <Button
          onClick={() => setShowModal(false)}
          variant="contained"
          color="warning"
        >
          Decline
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};
