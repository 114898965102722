import React, { useRef, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { SignatureUploader } from "./Tabs/SignatureUploader";
import { SignatureDraw } from "./Tabs/SignatureDraw";
import { SignatureSelect } from "./Tabs/SignatureSelect";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

interface SignnatureTabsProps {
  fullName: string;
  initials: string;
  setSignInitialsDetails: React.Dispatch<any>;
  signInitialsDetails: any;
}

export const SignnatureTabs: React.FC<SignnatureTabsProps> = ({
  fullName,
  initials,
  setSignInitialsDetails,
  signInitialsDetails,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (newValue === 0) {
      // console.log("Text");
      setSignInitialsDetails({
        ...signInitialsDetails,
        types: "Text",
      });
    } else if (newValue === 1) {
      // console.log("Draw");
      setSignInitialsDetails({
        ...signInitialsDetails,
        types: "Draw",
      });
    } else {
      // console.log("Uploaded");
      setSignInitialsDetails({
        ...signInitialsDetails,
        types: "Uploaded",
      });
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Choose" {...a11yProps(0)} />
          <Tab label="Draw" {...a11yProps(1)} />
          <Tab label="Upload" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <SignatureSelect
          fullName={fullName}
          initials={initials}
          signInitialsDetails={signInitialsDetails}
          setSignInitialsDetails={setSignInitialsDetails}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SignatureDraw
          signInitialsDetails={signInitialsDetails}
          setSignInitialsDetails={setSignInitialsDetails}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <SignatureUploader
          signInitialsDetails={signInitialsDetails}
          setSignInitialsDetails={setSignInitialsDetails}
        />
      </TabPanel>
    </Box>
  );
};
