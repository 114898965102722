import React, { useState, useEffect } from "react";
import { DocumentDetailsProvider } from "../../context/DocumentsDetails/documentDetails.context";
import { signActions } from "../helpers/Constants";
import Documentdetailsidebar from "../layouts/Documentdetailsidebar";
import { Headers } from "../layouts/Headers";
import { AddDocuments } from "./AddDocuments";
import { PrepareDocument } from "./PrepareDocument";
import { ReviewDetails } from "./ReviewDetails";
import { SelectRecipients } from "./SelectRecipients";
import { useSearchParams } from "react-router-dom";

interface DocumentDetailsProps {
  children?: React.ReactNode;
  // setType: any;
}

export const DocumentDetails: React.FC<DocumentDetailsProps> = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    let companyId = searchParams.get("id");
    if (companyId !== null) {
      setType(3);
    }
  }, []);

  const [type, setType] = useState<number>(0);

  const handlesignPackginfo = (documentHeaderId: string) => {
    let companyId = searchParams.get("id");
  };

  return (
    <>
      <Headers />
      <DocumentDetailsProvider>
        <Documentdetailsidebar type={type} setType={setType}>
          <div className="flex flex-col w-full px-4 overflow-y-auto overflow-x-hidden doc-container">
            {signActions[type] === "Add Documents" && (
              <AddDocuments type={type} setType={setType} />
            )}
            {signActions[type] === "Add Recipients" && (
              <SelectRecipients type={type} setType={setType} />
            )}
            {signActions[type] === "Review" && (
              <ReviewDetails type={type} setType={setType} />
            )}
            {signActions[type] === "Prepare" && (
              <div className="overflow-y-hidden overflow-x-hidden">
                <PrepareDocument type={type} setType={setType} />
              </div>
            )}
          </div>
        </Documentdetailsidebar>
      </DocumentDetailsProvider>
    </>
  );
};
